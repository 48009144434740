import React, { useEffect } from 'react';
import styles from '../Filter.module.css';
import FilterSelectGeneric from '../FilterSelectGeneric/FilterSelectGeneric';
import { Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export default function FilterMultiSelect(props) {
  const { label, options, value, onChange, dataColors, maxNumOfValues, indicator, withColors } = props;
  const filteredOptions = options.filter(x => value.every(t => !t || t.id !== x.id));
  const [availableOptions, setAvailableOptions] = React.useState(filteredOptions);

  useEffect(() => {
    if (value.length === 1 && value[0] === undefined && options) {
      setAvailableOptions(options);
    }
  }, [options, value]);

  const handleChange = React.useCallback(
    (selected, position) => {
      let tmp = [...value];

      // add or remove an option from filters
      if (selected === undefined) {
        tmp.splice(position, 1);
      } else if (selected[indicator]) {
        tmp = [selected];
      } else {
        tmp[position] = selected;
      }

      // filter the list of options to show only those that are not selected yet
      const optionsFiltered = options.filter(x => tmp.every(t => !t || t.id !== x.id));
      setAvailableOptions(optionsFiltered);

      // decide if we want to add a possibility to select another option
      if (
        tmp.every(x => x !== undefined) &&
        tmp.length < maxNumOfValues &&
        optionsFiltered.length &&
        (!selected || !selected[indicator])
      ) {
        tmp.push(undefined);
      }

      onChange(tmp);
    },
    [value, options, maxNumOfValues]
  );

  return (
    <div>
      <Text className={styles.filterLabel}>{label}</Text>
      {value.map((x, i) => {
        return (
          <FilterSelectGeneric
            key={x ? x.id : `filter-${i}`}
            value={x ? x.name : undefined}
            onChange={val => {
              const selected = options.find(_x => _x.name === val);
              handleChange(selected, i);
            }}
            options={availableOptions.map(p => p.name)}
            placeholder={value.length === 1 ? '+ Add establishment' : '+ Add comparison'}
            allowClear
            withColors={withColors}
            dataColors={dataColors}
          />
        );
      })}
    </div>
  );
}

FilterMultiSelect.propTypes = {
  label: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      name: PropTypes.string.isRequired,
    })
  ),
  value: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
  dataColors: PropTypes.objectOf(PropTypes.string),
  maxNumOfValues: PropTypes.number,
  indicator: PropTypes.string,
  withColors: PropTypes.bool,
};
