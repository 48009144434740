import { ResponsiveSankey } from '@nivo/sankey';
import React from 'react';
import COLORS from '../../../../modules/colors';
import { formatMarketQuantity, formatPercent } from '../../../../modules/format';
import styles from './SankeyChart.module.css';

export default function SankeyChart(props) {
  const { marketSupplyChainData, activeMarket, isLarge } = props;

  const packerPlantNodes = marketSupplyChainData.packerPlants.map(x => {
    return {
      id: x.name.replace(/'/g, ''),
      color: COLORS.PACKER_PLANT,
      volume: x.totalVolume,
    };
  });
  const grinderNodes = marketSupplyChainData.grinders?.map(x => {
    return {
      id: x.name.replace(/[()']/g, ''),
      color: COLORS.GRINDER,
      volume: x.totalVolume,
    };
  });

  const nodes = [...packerPlantNodes, ...grinderNodes];

  const links = [];
  marketSupplyChainData.packerPlants?.forEach(x => {
    const linksPerPackerPlant = x.orders.map(o => {
      return {
        source: x.name.replace(/'/g, ''),
        target: o.name.replace(/[()']/g, ''),
        value: o.volume,
      };
    });
    links.push(...linksPerPackerPlant);
  });

  return (
    <div style={{ height: isLarge ? `580px` : `380px` }}>
      <ResponsiveSankey
        data={{
          nodes,
          links,
        }}
        colors={d => d.color ?? ''}
        enableLinkGradient
        labelPosition="outside"
        margin={{ top: 20, right: 200, bottom: 20, left: 200 }}
        nodeTooltip={node => (
          <div>
            <div
              className={styles.entity}
              style={{
                backgroundColor: node.color,
              }}
            />
            <strong>{node.id}</strong>
            <br />
            <span style={{ paddingLeft: 15 }}>{formatMarketQuantity(activeMarket, 1, 1)(node.volume)}</span>
          </div>
        )}
        linkTooltip={node => {
          const sourceTotalVolume = packerPlantNodes.find(x => x.id === node.source.label)?.volume;
          const targetTotalVolume = grinderNodes.find(x => x.id === node.target.label)?.volume;
          return (
            <div style={{ display: 'flex' }}>
              <div style={{ marginRight: 20 }}>
                <div
                  className={styles.entity}
                  style={{
                    backgroundColor: COLORS.PACKER_PLANT,
                  }}
                />
                <strong>{node.source.label}</strong>
                <br />
                <span style={{ paddingLeft: 15 }}>
                  {formatPercent(0)(node.value / sourceTotalVolume)}
                  {' of volume'}
                </span>
                <br />
                <span style={{ paddingLeft: 15 }}>{formatMarketQuantity(activeMarket, 1, 1)(node.value)}</span>
              </div>
              <div>
                <div
                  className={styles.entity}
                  style={{
                    backgroundColor: COLORS.GRINDER,
                  }}
                />
                <strong>{node.target.label}</strong>
                <br />
                <span style={{ paddingLeft: 15 }}>
                  {formatPercent(0)(node.value / targetTotalVolume)}
                  {' of volume'}
                </span>
                <br />
                <span style={{ paddingLeft: 15 }}>{formatMarketQuantity(activeMarket, 1, 1)(node.value)}</span>
              </div>
            </div>
          );
        }}
      />
    </div>
  );
}
