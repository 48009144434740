import React from 'react';
import Select from 'react-select';
import { TREASURY_DETAILS_COST } from '../../../../slices/treasury-details/treasuryDetailsReducers';
import { Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const OPTIONS = [TREASURY_DETAILS_COST.TOTAL, TREASURY_DETAILS_COST.SAVINGS];

export default function FilterCost(props) {
  const { activeCost, onActiveCostChange, ...rest } = props;

  function handleChange(item) {
    onActiveCostChange(item.value);
  }

  return (
    <div>
      <Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        Cost
      </Text>

      <Select
        showSearch
        value={{ label: activeCost || '', value: activeCost || '' }}
        styles={{
          container: styles => ({
            ...styles,
            padding: '0px',
            textAlign: 'left',
          }),
          clearIndicator: styles => ({
            ...styles,
            padding: '0px',
            ...rest.clearIndicatorStyles,
          }),
          dropdownIndicator: styles => ({
            ...styles,
            padding: '0px',
            ...rest.dropdownIndicatorStyles,
          }),
        }}
        onChange={handleChange}
        options={OPTIONS.map(opt => ({ label: opt, value: opt }))}
      />
    </div>
  );
}

FilterCost.propTypes = {
  activeCost: PropTypes.string,
  onActiveCostChange: PropTypes.func.isRequired,
};
