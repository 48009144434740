import React from 'react';
import Select from 'react-select';
import { TREASURY_DETAILS_VOLUME } from '../../../../slices/treasury-details/treasuryDetailsReducers';
import { Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const OPTIONS = [
  TREASURY_DETAILS_VOLUME.TOTAL,
  TREASURY_DETAILS_VOLUME.ALL_STRATEGIES,
  TREASURY_DETAILS_VOLUME.FORMULA,
  TREASURY_DETAILS_VOLUME.SPOT,
  TREASURY_DETAILS_VOLUME.CONTRACT,
  TREASURY_DETAILS_VOLUME.NOF,
  TREASURY_DETAILS_VOLUME.HEDGED_VS_EXPOSED,
  TREASURY_DETAILS_VOLUME.HEDGED,
  TREASURY_DETAILS_VOLUME.EXPOSED,
  TREASURY_DETAILS_VOLUME.FAT_VS_LEAN,
];

export default function FilterVolume(props) {
  const { activeVolume, onActiveVolumeChange, ...rest } = props;

  function handleChange(item) {
    onActiveVolumeChange(item.value);
  }

  return (
    <div>
      <Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        Volume
      </Text>

      <Select
        showSearch
        value={{ label: activeVolume || '', value: activeVolume || '' }}
        styles={{
          container: styles => ({
            ...styles,
            padding: '0px',
            textAlign: 'left',
          }),
          clearIndicator: styles => ({
            ...styles,
            padding: '0px',
            ...rest.clearIndicatorStyles,
          }),
          dropdownIndicator: styles => ({
            ...styles,
            padding: '0px',
            ...rest.dropdownIndicatorStyles,
          }),
        }}
        onChange={handleChange}
        options={OPTIONS.map(opt => ({ label: opt, value: opt }))}
      />
    </div>
  );
}

FilterVolume.propTypes = {
  activeVolume: PropTypes.string,
  onActiveVolumeChange: PropTypes.func.isRequired,
};
