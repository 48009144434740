import React, { useEffect } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { ChevronRight } from '../../../../shared/Icons';
import styles from './ASLUpdates.module.css';
import {
  PopoverTitle,
  PopoverHeader,
  PopoverRoot,
  PopoverBody,
  PopoverTrigger,
  PopoverContent,
} from '@/components/ui/popover';

const ASLUpdates = props => {
  const [readMore, setReadMore] = React.useState(false);
  const [packerPlantOptions, setPackerPlantOptions] = React.useState(props.packerPlantOptions);

  useEffect(() => {
    setPackerPlantOptions(props.packerPlantOptions);
  }, [packerPlantOptions]);

  const formatAchievementDisplay = achievementData => {
    // everything in the brackets
    const displayData = achievementData.split('(');
    return displayData;
  };
  const { aslUpdates } = props;
  const getPackerPlantNameAndEstId = id => {
    const packerPlant = props.packerPlantOptions.filter(pp => pp.id.trim() === id.trim());
    return packerPlant.length > 0 ? `${packerPlant[0].name} - ${packerPlant[0].est}` : id;
  };

  const linkName = readMore ? 'Read Less' : 'Read More';
  let aslDisplayData = [];
  if (readMore) {
    aslDisplayData = aslUpdates.keyAchievements;
  } else {
    aslDisplayData = [aslUpdates.keyAchievements.filter(x => x.achievement.length > 0)[0]];
  }
  return (
    <div>
      <Text className={styles.QualityAsl_totalSuppliers} style={{"display":"flex","alignItems":"baseline","gap":"10px"}}>
        <span>{aslUpdates.packerPlantsCount}</span> suppliers{' '}
        <Box style={{ position: 'relative', width: 14 }}>
          <ChevronRight />
        </Box>
      </Text>
      <p className={styles.QualityAsl_numbers}>
        {/* <strong>{aslUpdates.packerPlantsCount - aslUpdates.newSuppliersOnboarding.length}</strong> approved{' '} */}
        {/* <span className={styles.QualityAsl_divider}>|</span> <strong>{aslUpdates.newSuppliersOnboarding.length}</strong>{' '} */}
        {/* <span className={styles.QualityAsl_newBadge}>new</span> <span className={styles.QualityAsl_divider}>|</span>{' '} */}
        {/* <strong>{aslUpdates.delistedSuppliersNumber}</strong> de-listed */}
      </p>
      <div className={styles.QualityAsl_tables}>
        <div className={styles.QualityAsl_table}>
          <div className={styles.QualityAsl_title}>
            <Text>
              Key Achievements <span>({aslUpdates.keyAchievements.filter(x => x.achievement.length > 0).length})</span>
            </Text>
          </div>
          {/* Example data structure
            aslDisplayData = [
            {"category": "Volume", "achievement": []}, 
            {"category": "NCRs", "achievement": ["Average Packer NCR incident rate per 10M tons is smaller than 0.02: 2021 - 0.117 : 2022 - 0.043 ",
              "NCR Incident Rate per 10M tons is smaller than 0.02: 2021 - 4, (NOLGY, SFFTE, SFFPA, JBARO): 2022 - 2, (NOLGY, AMGDA)"]}, 
            {"category": "FO", "achievement": ["Average Packer FO incident rate per 10M tons is smaller than 0.02: 2021 - 0.074 ",
              "FO Incident Rate per 10M tons is smaller than 0.02: 2021 - 3, (NOLGY, AMGDA, GREMO): 2022 - 1, (AMGDA)"]}, 
            {"category": "Audits", "achievement": ["SWA Progress Update - 2021 - 16 establishments out of 51"]}, 
            {"category": "DIFOT", "achievement": ["DIFOT higher than 95%: 2021 - 1 out of 49 Establishments"]}]
          ] */}

          {aslDisplayData.map(
            (x, i) =>
              x?.achievement.length > 0 &&
              x?.achievement.map(achv => {
                const achvData = achv.split(':');
                return (
                  <>
                    <div className={styles.QualityAsl_tableRow} data-vertical key={`subCat-${i}`}>
                      {x?.category} - {achvData[0]}{' '}
                    </div>
                    {achvData.slice(1).length > 0 && (
                      <div
                        style={{
                          width: `100%`,
                          paddingLeft: 30,
                          color: '#0B1435',
                          opacity: 0.6,
                        }}
                      >
                        <ul className={styles.QualityAsl_ul}>
                          <li>
                            {achvData.slice(1).map(innerAchvData => {
                              // eslint-disable-next-line no-undef
                              const displayData = formatAchievementDisplay(innerAchvData);
                              return displayData.length > 1 ? (
                                <li>
                                  {/* to remove additional comma that is needed to split data but
                                  not nessary to display on UI */}
                                  {displayData[0].slice(0, -2)}
                                  <PopoverRoot>
                                    <PopoverTrigger asChild>
                                      <i className="fa fa-info-circle" />
                                    </PopoverTrigger>
                                    <PopoverHeader>
                                      <PopoverTitle>PackerPlant Name - Est</PopoverTitle>
                                    </PopoverHeader>
                                    <PopoverBody>
                                      <PopoverContent>
                                        <ul className={styles.QualityAsl_popoverList}>
                                          {displayData[1]
                                            .slice(0, -1)
                                            .split(',')
                                            .map(id => {
                                              return (
                                                <li style={{ paddingLeft: '5px' }} key={id}>
                                                  {getPackerPlantNameAndEstId(id)}
                                                </li>
                                              );
                                            })}
                                        </ul>
                                      </PopoverContent>
                                    </PopoverBody>
                                  </PopoverRoot>
                                </li>
                              ) : (
                                <li> {displayData[0]} </li>
                              );
                            })}
                          </li>
                        </ul>
                      </div>
                    )}
                  </>
                );
              })
          )}
          <a
            className={styles.QualityAsl_readmoreAnchor}
            onClick={() => {
              setReadMore(!readMore);
            }}
          >
            <strong className={styles.QualityAsl_readmoreText}>{linkName}</strong>
          </a>
        </div>
      </div>
    </div>
  );
};

const Progress = props => {
  const { number } = props;
  const steps = Array.from(Array(10).keys());
  return (
    <div className={styles.QualityAsl_progress}>
      {steps.map((x, i) => {
        return <div className={styles.QualityAsl_step} key={`step-${i}`} data-completed={i < number} />;
      })}
    </div>
  );
};

export default ASLUpdates;
