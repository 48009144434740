import React from 'react';
import { HStack } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { TREASURY_TIME_INTERVAL } from '../../../../slices/treasury/treasuryConfig';
import { RadioGroup, Radio } from '@/components/ui/radio';

export default function FilterTimeScale(props) {
  const { timeScale, onTimeScaleChange, size } = props;
  const isSmall = size === 'small';
  return (
    <div>
      <RadioGroup value={timeScale} onValueChange={e => onTimeScaleChange(e.value)}>
        <HStack alignItems="center" justifyContent="end">
          <Radio value={TREASURY_TIME_INTERVAL.YEARLY} disabled>
            {isSmall ? 'Y' : 'Yearly'}
          </Radio>
          <Radio value={TREASURY_TIME_INTERVAL.QUARTERLY}>{isSmall ? 'Q' : 'Quarterly'}</Radio>
          <Radio value={TREASURY_TIME_INTERVAL.MONTHLY}>{isSmall ? 'M' : 'Monthly'}</Radio>
        </HStack>
      </RadioGroup>
    </div>
  );
}

FilterTimeScale.propTypes = {
  timeScale: PropTypes.string.isRequired,
  onTimeScaleChange: PropTypes.func.isRequired,
  size: PropTypes.string,
};
