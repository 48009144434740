import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';
import { Separator } from '@chakra-ui/react';

export default function FilterGrinders(props) {
  const { value, options, onChange, ...rest } = props;

  const handleChange = val => {
    const selectedGrinder = options.find(x => x.id === val.value);
    if (selectedGrinder) {
      onChange(selectedGrinder);
    }
  };

  const createOption = (name, id) => {
    if (id === 'Divider') {
      return { label: <Separator />, value: id, isDisabled: true };
    }
    return { label: name, value: id };
  };

  return (
    <Select
      showSearch
      value={{ label: value?.name || '', value: value?.id || '' }}
      styles={{
        container: styles => ({
          ...styles,
          padding: '0px',
          textAlign: 'left',
        }),
        control: styles => ({
          ...styles,
          maxHeight: '225px',
          width: '234px',
          overflowY: 'scroll',
          ...rest.controlStyles,
        }),
        clearIndicator: styles => ({
          ...styles,
          padding: '0px',
          ...rest.clearIndicatorStyles,
        }),
        dropdownIndicator: styles => ({
          ...styles,
          padding: '0px',
          ...rest.dropdownIndicatorStyles,
        }),
      }}
      onChange={handleChange}
      options={options.map(({ id, name }) => {
        return createOption(name, id);
      })}
    />
  );
}

FilterGrinders.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
    })
  ),
  onChange: PropTypes.func,
};
