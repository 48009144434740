import React from 'react';
import PropTypes from 'prop-types';

const AuthGate = ({ requiredPermissions, anyOfPermissions, permissionsExclude, user, children }) => {
  // Allow for pass-through when no permission settings are used. This is helpful for programmatic
  // use of the component where you may or may not want to pass permission settings. In the case that you
  // pass no permissions settings, the component will immediately render children.
  if (!requiredPermissions && !anyOfPermissions && !permissionsExclude) {
    return <>{children || null}</>;
  }

  const { role } = user;
  if (!role || !role.access_list) {
    return null;
  }
  // TODO Improve this to account for more sophisticated permissions conditions, e.g., orgs, specific roles, etc
  if (requiredPermissions && requiredPermissions.every(permission => role.access_list.includes(permission))) {
    return <>{children || null}</>;
  }

  if (anyOfPermissions && anyOfPermissions.some(permission => role.access_list.includes(permission))) {
    // Note: making this an if after a conditional return means that requiredPermissions will superceded anyOfPermissions and you can't
    // do anything like 3 required permissions and any of a list of 2 more. That's probably OK for us for now.
    return <>{children || null}</>;
  }

  if (permissionsExclude && permissionsExclude.every(permission => !role.access_list.includes(permission))) {
    return <>{children || null}</>;
  }

  // Don't show anything
  return <></>;
};

AuthGate.propTypes = {
  requiredPermissions: PropTypes.arrayOf(PropTypes.string),
  anyOfPermissions: PropTypes.arrayOf(PropTypes.string),
  permissionsExclude: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.instanceOf(Object).isRequired,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default AuthGate;
