import React from 'react';
import { useSelector } from 'react-redux';
import { calculateRoundedMax } from '../../../../../modules/data/rounded-max';
import { formatSI, formatWithCommas } from '../../../../../modules/format';
import { selectQualityClaimsTimeRange } from '../../../../../slices/quality-details-claims/selectors/selectBasicDetailsClaimsData';
import { TIME_RANGE_OPTIONS } from '../../../../../slices/quality-details-claims/qualityDetailsClaimsConfig';
import BaseChart from '../../../treasury/BaseChart/BaseChart';
import { equalsIgnoreOrder } from '../../../../../utils';
import { useChartData } from '../../../../../containers/analyticDashboard/qualityDetails/claims/QualityDetailsClaimsCharts';
import styles from '../../QualityDetails.module.css';
import { Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export default function CountChart(props) {
  const { isLarge, columns, colors, selectedCategories, selectedClaimAmounts, selectedDaysToResolve } = props;

  const timeRange = useSelector(selectQualityClaimsTimeRange);

  const countData = useChartData(`count`, selectedCategories, selectedClaimAmounts, selectedDaysToResolve);

  const total = React.useMemo(() => {
    let sum = 0;
    if (equalsIgnoreOrder(columns, ['Finalised', 'Submitted'])) {
      columns.forEach(x => {
        if (x === 'Submitted') {
          sum += countData.reduce((a, b) => a + (b[x] || 0), 0);
        }
      });
    } else {
      columns.forEach(x => {
        sum += countData.reduce((a, b) => a + (b[x] || 0), 0);
      });
    }
    return sum;
  }, [countData, columns]);

  if (countData.length === 0) return null;

  return (
    <div className={styles.QualityDetails_countChart}>
      <div className={styles.QualityDetails_chartTitle}>
        <div style={{ color: '#0B1435' }}>Total number of incidents</div>
        {timeRange === TIME_RANGE_OPTIONS.HISTORICAL && (
          <div className={styles.QualityDetails_chartTotal}>
            <Text>
              <span style={{ opacity: 0.6 }}>Total</span> <strong>{formatSI(1)(total || 0)}</strong>
            </Text>
          </div>
        )}
      </div>
      <BaseChart
        barData={countData}
        columns={columns}
        colors={colors}
        isLarge={isLarge}
        splitIndex={-1}
        noCurrentLine
        noLegend
        ticksNumber={4}
        formatValues={formatSI(1)}
        pathColors={colors}
        minMaxGenerator={maxData => {
          return {
            maxVal: calculateRoundedMax(maxData),
            minVal: 0,
          };
        }}
        formatTooltip={val => `${formatWithCommas(val)} incidents`}
        padding={{ right: 2 }}
        isLineChart={timeRange === TIME_RANGE_OPTIONS.YOY}
        noLastPoint
        isYearOnYear={timeRange === TIME_RANGE_OPTIONS.YOY}
      />
    </div>
  );
}

CountChart.propTypes = {
  isLarge: PropTypes.bool,
  columns: PropTypes.arrayOf(PropTypes.string),
  colors: PropTypes.arrayOf(PropTypes.string),
  selectedCategories: PropTypes.arrayOf(PropTypes.string),
  selectedClaimAmounts: PropTypes.arrayOf(PropTypes.number),
  selectedDaysToResolve: PropTypes.arrayOf(PropTypes.number),
};
