import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { endOfMonth, endOfYear, startOfMonth, startOfYear } from 'date-fns';
import styles from '../Filter.module.css';
import { FREQUENCY } from '../../../../../slices/quality/qualityConfig';
import { Text } from '@chakra-ui/react';
import DatetimeRangePicker from 'react-datetime-range-picker';

export default function FilterTimePeriod(props) {
  const { value, onChange, frequency } = props;

  return (
    <div>
      <div style={{ height: 30 }}>
        <Text className={styles.filterLabel}> </Text>
      </div>
      {/* <DatePicker.RangePicker
        className={styles.datePicker}
        picker={frequency === FREQUENCY.ANNUALLY ? 'year' : 'month'}
        value={value.map(d => moment(d))}
        format={m => {
          if (frequency === FREQUENCY.ANNUALLY) {
            return m.format('YYYY');
          }
          return m.format('MMM, YYYY');
        }}
        disabledDate={current => {
          return current && current > moment().subtract(1, 'month');
        }}
        onChange={newPeriod => {
          if (!newPeriod) return;
          if (frequency === FREQUENCY.ANNUALLY) {
            onChange([startOfYear(newPeriod[0].toDate().getTime()), endOfYear(newPeriod[1].toDate().getTime())]);
          } else {
            onChange([startOfMonth(newPeriod[0].toDate().getTime()), endOfMonth(newPeriod[1].toDate().getTime())]);
          }
        }}
      /> */}
      <DatetimeRangePicker
        className="date-range-picker"
        inputProps={{
          placeholder: 'Date range',
        }}
        closeOnSelect
        startDate={moment(value[0]).toDate()}
        endDate={moment(value[1]).toDate()}
        onChange={e => {
          if (frequency === FREQUENCY.ANNUALLY) {
            onChange([startOfYear(e.startDate).toDate().getTime(), endOfYear(e.endDate).toDate().getTime()]);
          } else {
            onChange([startOfMonth(e.startDate.toDate().getTime()), endOfMonth(e.endDate.toDate().getTime())]);
          }
        }}
        timeFormat={false}
        dateFormat="DD/MM/YYYY"
      />
    </div>
  );
}

FilterTimePeriod.propTypes = {
  frequency: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.instanceOf(Date)).isRequired,
};
