/* eslint-disable consistent-return */
import React from 'react';
import { useSelector } from 'react-redux';
import { useChartData } from '../../../../../containers/analyticDashboard/qualityDetails/ncrs/QualityDetailsNCRsCharts';
import { calculateRoundedMax } from '../../../../../modules/data/rounded-max';
import { formatPercent, formatSI } from '../../../../../modules/format';
import {
  PACKER_MODE,
  TIME_RANGE_OPTIONS,
  VOLUME_OPTIONS,
} from '../../../../../slices/quality-details-ncrs/qualityDetailsNcrsConfig';
import {
  selectQualityNCRsTimeRange,
  selectQualityNCRsVolume,
  selectQualityNCRsMarket,
} from '../../../../../slices/quality-details-ncrs/selectors/selectBasicDetailsNcrsData';
import BaseChart from '../../../treasury/BaseChart/BaseChart';
import {
  isLargePropTypes,
  columnsPropTypes,
  colorsPropTypes,
  selectedCategoriesPropTypes,
  selectedSubcategoriesPropTypes,
  packerModePropTypes,
} from '../../../../../slices/quality-details-ncrs/qualityDetailsNcrsPropTypes';
import styles from '../../QualityDetails.module.css';
import { Text } from '@chakra-ui/react';

export default function VolumeChart(props) {
  const { isLarge, columns, colors, selectedCategories, selectedSubcategories, packerMode } = props;

  const volumeMode = useSelector(selectQualityNCRsVolume);
  const timeRange = useSelector(selectQualityNCRsTimeRange);
  const market = useSelector(selectQualityNCRsMarket);

  const fieldName = React.useMemo(() => {
    switch (volumeMode) {
      case VOLUME_OPTIONS[market.key].PERCENT_OF_TOTAL:
        return 'percentOfTotalVolume';
      case VOLUME_OPTIONS[market.key].INCIDENTS_RATE:
        return 'incidentRate';
      case VOLUME_OPTIONS[market.key].WEIGHT:
        return 'weight';
      case VOLUME_OPTIONS[market.key].PERCENT_OF_MARKET:
        return 'percentOfMarket';
      default:
        return '';
    }
  }, [volumeMode]);

  const chartTitle = React.useMemo(() => {
    const incidentRate = VOLUME_OPTIONS[market.key].INCIDENTS_RATE;
    switch (volumeMode) {
      case VOLUME_OPTIONS[market.key].PERCENT_OF_TOTAL:
        return '% of all volume supplied';
      case VOLUME_OPTIONS[market.key].INCIDENTS_RATE:
        return `Number of ${incidentRate.charAt(0).toLowerCase() + incidentRate.slice(1)}`;
      case VOLUME_OPTIONS[market.key].WEIGHT:
        return `Weight (${market.quantityUnit})`; // TODO make unit dynamic
      case VOLUME_OPTIONS[market.key].PERCENT_OF_MARKET:
        return '% of total market volume';
      default:
    }
  }, [volumeMode]);

  const formatValues = React.useMemo(() => {
    switch (volumeMode) {
      case VOLUME_OPTIONS[market.key].PERCENT_OF_TOTAL:
        return formatPercent(4);
      case VOLUME_OPTIONS[market.key].INCIDENTS_RATE:
        return formatSI(3);
      case VOLUME_OPTIONS[market.key].WEIGHT:
        return formatSI(1);
      case VOLUME_OPTIONS[market.key].PERCENT_OF_MARKET:
        return formatPercent(1);
      default:
    }
  }, [volumeMode, market]);

  const formatTooltip = React.useMemo(() => {
    switch (volumeMode) {
      case VOLUME_OPTIONS[market.key].PERCENT_OF_TOTAL:
        return formatPercent(4);
      case VOLUME_OPTIONS[market.key].INCIDENTS_RATE:
        return formatSI(3);
      case VOLUME_OPTIONS[market.key].WEIGHT:
        return v => `${formatSI(1)(v)} ${market.quantityUnit}s`;
      case VOLUME_OPTIONS[market.key].PERCENT_OF_MARKET:
        return formatPercent(1);
      default:
    }
  }, [volumeMode, market]);

  const volumeData = useChartData(
    `${fieldName}${packerMode === PACKER_MODE.BOTTOM_10_TOTAL ? 'BottomTen' : ''}`,
    selectedCategories,
    selectedSubcategories
  );
  const average = React.useMemo(() => {
    let avg = 0;
    columns.forEach(x => {
      avg +=
        volumeData.reduce((a, b) => {
          if (x in b) {
            return a + b[x];
          }
          return a;
        }, 0) / volumeData.length;
    });
    return avg / columns.length;
  }, [volumeData, columns]);

  if (volumeData.length === 0) return null;
  const filteredVolumeData = volumeData.filter(d => !!d);
  columns.forEach(c => {
    if (colors[c] === undefined) {
      // TODO use sophisticated color picker
      colors[c] = '#4E5984';
    }
    filteredVolumeData.forEach(data => {
      if (data[c] === undefined) {
        data[c] = 0;
      }
    });
  });

  return (
    <div className={styles.QualityDetails_volumeChart}>
      <div className={styles.QualityDetails_chartTitle}>
        <div style={{ color: '#0B1435' }}>{chartTitle}</div>
        {timeRange === TIME_RANGE_OPTIONS.HISTORICAL && (
          <div className={styles.QualityDetails_chartTotal}>
            <Text>
              <span style={{ opacity: 0.6 }}>Average</span> <strong>{formatValues(average || 0)}</strong>
            </Text>
          </div>
        )}
      </div>
      <BaseChart
        barData={filteredVolumeData}
        columns={columns}
        colors={colors}
        isLarge={isLarge}
        splitIndex={-1}
        noCurrentLine
        noLegend
        ticksNumber={4}
        formatValues={formatValues}
        pathColors={colors}
        minMaxGenerator={maxData => {
          return {
            maxVal: calculateRoundedMax(maxData),
            minVal: 0,
          };
        }}
        formatTooltip={formatTooltip}
        padding={{ right: 2 }}
        isLineChart
        noLastPoint
        isYearOnYear={timeRange === TIME_RANGE_OPTIONS.YOY}
      />
    </div>
  );
}

VolumeChart.propTypes = {
  isLarge: isLargePropTypes,
  columns: columnsPropTypes,
  colors: colorsPropTypes,
  selectedCategories: selectedCategoriesPropTypes,
  selectedSubcategories: selectedSubcategoriesPropTypes,
  packerMode: packerModePropTypes,
};
