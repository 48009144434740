import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import Loading from '../Loading';
import './EmailModal.scss';
import {
  Box,
  Button as ChakraButton,
  Flex,
  Text,
  Grid,
  GridItem,
  VStack,
  Input as ChakraInput,
  Group,
  Textarea,
  Spinner,
} from '@chakra-ui/react';
import {
  DialogRoot,
  DialogContent,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogCloseTrigger,
} from '@/components/ui/dialog';
import { Alert } from '@/components/ui/alert';

const propTypes = {
  attachmentList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      docType: PropTypes.string.isRequired,
    })
  ),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  className: PropTypes.string,
  initialValues: PropTypes.shape({
    body: PropTypes.string,
    subject: PropTypes.string,
  }),
  error: PropTypes.string,
  handleSubmit: PropTypes.func,
  isDownloadingAllAttachments: PropTypes.bool,
  isLoadingAttachments: PropTypes.bool,
  isSendingEmail: PropTypes.bool,
  isVisible: PropTypes.bool,
  onAttachmentClick: PropTypes.func,
  onCancel: PropTypes.func,
  onDownloadAllClick: PropTypes.func,
  pendingPDFDownloadList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      docType: PropTypes.string.isRequired,
    })
  ),
  recipientsComponent: PropTypes.element,
};

const EmailModal = ({
  error,
  isVisible,
  onCancel,
  attachmentList,
  onDownloadAllClick,
  onAttachmentClick,
  pendingPDFDownloadList,
  isLoadingAttachments,
  isDownloadingAllAttachments,
  children,
  title,
  text,
  // Below values provided by Formik:
  setFieldValue,
  handleSubmit,
  values,
  recipientsComponent,
}) => {
  return (
    <DialogRoot open={isVisible} onClose={onCancel}>
      <DialogContent minWidth="5xl" maxW="80%">
        <DialogHeader borderBottomWidth={1} fontSize="16px" fontWeight="normal">
          <Text as="p" textAlign="center">
            {title || 'Email Preview'}
          </Text>
        </DialogHeader>
        <DialogCloseTrigger asChild onClick={onCancel} />
        <DialogBody padding={10}>
          <form onSubmit={handleSubmit}>
            {error && <Alert status="error" title={error} />}
            {text && (
              <Text as="p" textAlign="center" color="gray">
                {text}
              </Text>
            )}
            <Grid templateColumns="repeat(12, 1fr)" gap={6}>
              <GridItem colSpan={9}>
                <VStack gap={6} align="stretch">
                  {/* <!-- CHILDREN --> */}
                  {children && children}
                  {/* <!-- SUBHEADER --> */}
                  <Text as="h6">Email Preview</Text>
                  <Box>
                    <Text as="label" fontSize="13px" fontWeight="bold">
                      Subject
                    </Text>
                    <ChakraInput
                      name="subject"
                      placeholder="Subject"
                      value={values.subject}
                      onChange={e => {
                        const value = get(e, 'target.value', '');
                        setFieldValue('subject', value);
                      }}
                    />
                  </Box>
                  {/* <!-- BODY --> */}
                  <Box>
                    <Text as="label" fontSize="13px" fontWeight="bold">
                      Message
                    </Text>
                    <Textarea
                      name="body"
                      value={values.body}
                      placeholder="Body"
                      rows={8}
                      onChange={e => {
                        const value = get(e, 'target.value', '');
                        setFieldValue('body', value);
                      }}
                    />
                  </Box>

                  {/* <!-- RECIPIENTS --> */}
                  <Box>{recipientsComponent}</Box>
                </VStack>
              </GridItem>
              <GridItem colSpan={3}>
                <Box>
                  {/* <!-- SUBHEADER --> */}
                  <Text as="h6">Attachments</Text>
                  {/* <!-- ATTACHMENTS --> */}
                  <VStack gap={10} align="stretch">
                    {isLoadingAttachments ? (
                      <Loading />
                    ) : (
                      attachmentList.map((attachment, idx) => {
                        const isLoading = pendingPDFDownloadList
                          .map(attachment => attachment.id)
                          .includes(attachment.id);
                        return (
                          <Flex
                            justifyContent="center"
                            alignItems="center"
                            wrap="wrap"
                            minW="100px"
                            height="35px"
                            color="white"
                            key={`attachment-${idx}`}
                            cursor={!isDownloadingAllAttachments && isLoading ? 'not-allowed' : 'pointer'}
                            bgColor={!isDownloadingAllAttachments && isLoading ? '#d2d0d0' : 'gray'}
                            onClick={() => onAttachmentClick(attachment)}
                          >
                            <Text as="p">{attachment.docType}</Text>
                            {!isDownloadingAllAttachments && isLoading && <Spinner size="sm" />}
                          </Flex>
                        );
                      })
                    )}
                    {/* <!-- DOWNLOAD LINK --> */}
                    {isDownloadingAllAttachments ? (
                      <Loading />
                    ) : (
                      !isEmpty(attachmentList) && (
                        <ChakraButton
                          variant="link"
                          colorScheme="blue"
                          htmlType="button"
                          onClick={() => onDownloadAllClick(attachmentList)}
                        >
                          <Text as="p" fontSize="12px">
                            Download All
                          </Text>
                        </ChakraButton>
                      )
                    )}
                  </VStack>
                </Box>
              </GridItem>
            </Grid>
          </form>
        </DialogBody>
        <DialogFooter padding={4}>
          <DialogCloseTrigger asChild>
            <Group>
              <ChakraButton
                type="submit"
                borderRadius={2}
                width={40}
                colorScheme="blue"
                variant="solid"
                mr={2}
                onClick={handleSubmit}
              >
                Email
              </ChakraButton>
              <ChakraButton
                variant="outline"
                _hover={{
                  bg: 'white',
                  color: 'blue.600',
                  borderColor: 'blue.600',
                }}
                borderRadius={2}
                width={40}
                onClick={onCancel}
              >
                Cancel
              </ChakraButton>
            </Group>
          </DialogCloseTrigger>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
};

EmailModal.propTypes = propTypes;

export default EmailModal;
