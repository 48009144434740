import React from 'react';
import './Loading.scss';
import { Spinner } from '@chakra-ui/react';

const Loading = ({ style }) => {
  return (
    <div className="loading" style={style}>
      <Spinner size="sm" />
      <div>Loading</div>
    </div>
  );
};

export default Loading;
