import React, { useState, useEffect } from 'react';
import { VStack, Input, Button, Text } from '@chakra-ui/react';
import { FaCheckCircle } from 'react-icons/fa';
import { addTwoFactorAuthMethod, deleteTwoFactorAuthMethod, sendOtpEmail } from '../../services/two-factor-auth';
import {
  DialogRoot,
  DialogContent,
  DialogHeader,
  DialogBody,
  DialogFooter,
  DialogCloseTrigger,
} from '@/components/ui/dialog';
import { Field } from '@/components/ui/field';
import PropTypes from 'prop-types';

// eslint-disable-next-line react/prop-types
export default function TwoFactorMethodModal({ user, method, isActive, handleChangeActiveStatus }) {
  const [isPasswordVisible, setPasswordVisible] = useState(true);
  const [methodStatus, setMethodStatus] = useState(isActive);
  const [password, setPassword] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  const closeModal = () => {
    setIsOpen(false);
  };

  useEffect(() => {
    setIsOpen(true);
  }, []);

  const onClose = async () => {
    if (method === 'email' && !isActive) await sendOtpEmail(user.email);
    handleChangeActiveStatus(methodStatus, success);
  };

  const confirmPassword = async () => {
    if (isActive) {
      // deactivate/delete
      const deleted = await deleteTwoFactorAuthMethod(user.token, method, password);
      if (deleted) {
        setPasswordVisible(false);
        setMethodStatus(!isActive);
        setSuccess(true);
      }
    } else {
      // activate / add
      const added = await addTwoFactorAuthMethod(user.token, method, password);
      if (added) {
        setPasswordVisible(false);
        setMethodStatus(!isActive);
        setSuccess(true);
      }
    }
  };
  return (
    <DialogRoot centered open={isOpen} onClose={onClose} size="lg">
      <DialogContent>
        <DialogHeader textAlign="center">
          {!isActive && (
            <Text as="h2" fontSize="16px">
              Activate 2FA Authentication
            </Text>
          )}
          {isActive && (
            <Text as="h2" fontSize="16px">
              Deactivate 2FA Authentication
            </Text>
          )}
        </DialogHeader>
        <DialogCloseTrigger onClick={onClose} />
        <DialogBody>
          {isPasswordVisible && (
            <Field label="Confirm your account password">
              <Input
                type="password"
                autoComplete="off"
                placeholder="Password"
                name="password"
                value={password}
                onChange={e => setPassword(e.target.value)}
              />
            </Field>
          )}
          {success && methodStatus && (
            <VStack>
              <FaCheckCircle h={8} w={8} color="green.500" />
              <Text as="h2" color="green.500" fontWeight="bold">
                Successfully Added
              </Text>
              <Text as="p">Please verify the 2FA method</Text>
            </VStack>
          )}
          {success && !methodStatus && (
            <VStack>
              <FaCheckCircle h={8} w={8} color="green.500" />
              <Text as="h2" color="green.500" fontWeight="bold">
                Successfully Deactivated
              </Text>
            </VStack>
          )}
        </DialogBody>
        <DialogFooter>
          <DialogCloseTrigger asChild>
            {isPasswordVisible && (
              <>
                <Button mr={3} onClick={closeModal}>
                  Cancel
                </Button>
                <Button onClick={() => confirmPassword()} colorScheme="actionSecondary">
                  Continue
                </Button>
              </>
            )}
            {success && methodStatus && (
              <>
                {!success && (
                  <Button mr={3} onClick={closeModal}>
                    Cancel
                  </Button>
                )}

                <Button onClick={() => onClose()} colorScheme="actionSecondary">
                  Verify
                </Button>
              </>
            )}
            {success && !methodStatus && (
              <Button onClick={() => onClose()} colorScheme="actionSecondary">
                Done
              </Button>
            )}
          </DialogCloseTrigger>
        </DialogFooter>
      </DialogContent>
    </DialogRoot>
  );
}

TwoFactorMethodModal.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string.isRequired,
    token: PropTypes.string.isRequired,
  }).isRequired,
  method: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  handleChangeActiveStatus: PropTypes.func.isRequired,
};
