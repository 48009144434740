import React from 'react';
import Select from 'react-select';
import { Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export default function FilterPackerPlant(props) {
  const { packer_plant, packer_plants, onActivePackerPlantChange, ...rest } = props;

  return (
    <div>
      <Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        Filter by establishment
      </Text>

      <Select
        showSearch
        value={{ label: packer_plant || '', value: packer_plant || '' }}
        styles={{
          container: styles => ({
            ...styles,
            padding: '0px',
            textAlign: 'left',
          }),
          clearIndicator: styles => ({
            ...styles,
            padding: '0px',
            ...rest.clearIndicatorStyles,
          }),
          dropdownIndicator: styles => ({
            ...styles,
            padding: '0px',
            ...rest.dropdownIndicatorStyles,
          }),
        }}
        onChange={e => onActivePackerPlantChange(e.value)}
        options={packer_plants.map(opt => ({ label: opt, value: opt }))}
      />
    </div>
  );
}

FilterPackerPlant.propTypes = {
  packer_plant: PropTypes.string,
  packer_plants: PropTypes.arrayOf(PropTypes.string),
  onActivePackerPlantChange: PropTypes.func,
};
