import React from 'react';
import Select from 'react-select';
import { TREASURY_DETAILS_PRICE } from '../../../../slices/treasury-details/treasuryDetailsReducers';
import { Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

const OPTIONS = [
  TREASURY_DETAILS_PRICE.MARKET_PRICE_AND_FORECAST,
  TREASURY_DETAILS_PRICE.COST_PRICE,
  TREASURY_DETAILS_PRICE.PRICE_SAVINGS,
];

export default function FilterPrice(props) {
  const { activePrice, onActivePriceChange, ...rest } = props;

  function handleChange(item) {
    onActivePriceChange(item.value);
  }

  return (
    <div>
      <Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        Price
      </Text>

      <Select
        showSearch
        value={{ label: activePrice || '', value: activePrice || '' }}
        styles={{
          container: styles => ({
            ...styles,
            padding: '0px',
            textAlign: 'left',
          }),
          clearIndicator: styles => ({
            ...styles,
            padding: '0px',
            ...rest.clearIndicatorStyles,
          }),
          dropdownIndicator: styles => ({
            ...styles,
            padding: '0px',
            ...rest.dropdownIndicatorStyles,
          }),
        }}
        onChange={handleChange}
        options={OPTIONS.map(opt => ({ label: opt, value: opt }))}
      />
    </div>
  );
}

FilterPrice.propTypes = {
  activePrice: PropTypes.string,
  onActivePriceChange: PropTypes.func.isRequired,
};
