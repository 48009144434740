import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { startOfDay, subYears } from 'date-fns';
import { Flex, HStack, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { Checkbox } from '@/components/ui/checkbox';
import { RadioGroup, Radio } from '@/components/ui/radio';
import DatetimeRangePicker from 'react-datetime-range-picker';

export default function FilterTimeRange(props) {
  const { timeRange, onTimeRangeChange, showOutlook, onShowOutlookChange } = props;

  const [type, setType] = useState(undefined);

  useEffect(() => {
    if (
      timeRange[0] === startOfDay(subYears(new Date(), 5)).getTime() &&
      timeRange[1] === startOfDay(new Date()).getTime()
    ) {
      setType('max');
    }
  }, [timeRange]);

  const now = startOfDay(new Date()).getTime();

  const oneYearAgo = startOfDay(subYears(new Date(), 1)).getTime();
  const threeYearsAgo = startOfDay(subYears(new Date(), 3)).getTime();
  const maxYearsAgo = startOfDay(subYears(new Date(), 5)).getTime();

  return (
    <div>
      <Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        Time range
      </Text>
      <div>
        <Flex alignItems="center" justifyContent="space-between" gap="10px">
          <RadioGroup value={type} onValueChange={e => setType(e.value)}>
            <HStack alignItems="center" justifyContent="end">
              <Radio value="one_year" onClick={() => onTimeRangeChange([oneYearAgo, now])}>
                1Y
              </Radio>
              <Radio value="three_years" onClick={() => onTimeRangeChange([threeYearsAgo, now])}>
                3Y
              </Radio>
              <Radio value="max" onClick={() => onTimeRangeChange([maxYearsAgo, now])}>
                MAX
              </Radio>
            </HStack>
          </RadioGroup>

          {/* <DatePicker.RangePicker
            picker="date"
            value={timeRange.map(d => moment(d))}
            format="DD/MM/YYYY"
            onChange={newPeriod => {
              if (!newPeriod) return;
              console.log(
                'newPeriod',
                newPeriod.map(m => m.toDate().getTime())
              );
              onTimeRangeChange(newPeriod.map(m => m.toDate().getTime()));
              setType(undefined);
            }}
          /> */}
          <DatetimeRangePicker
            className="date-range-picker"
            inputProps={{
              placeholder: 'Date range',
            }}
            closeOnSelect
            startDate={moment(timeRange[0]).toDate()}
            endDate={moment(timeRange[1]).toDate()}
            onChange={e => {
              onTimeRangeChange([moment(e.startDate).toDate().getTime(), moment(e.endDate).toDate().getTime()]);
              setType(undefined);
            }}
            timeFormat={false}
            dateFormat="DD/MM/YYYY"
          />
          <Checkbox onChange={() => onShowOutlookChange(!showOutlook)} checked={showOutlook}>
            Show outlook
          </Checkbox>
        </Flex>
      </div>
    </div>
  );
}

FilterTimeRange.propTypes = {
  timeRange: PropTypes.arrayOf(PropTypes.number).isRequired,
  onTimeRangeChange: PropTypes.func.isRequired,
  showOutlook: PropTypes.bool.isRequired,
  onShowOutlookChange: PropTypes.func.isRequired,
};
