import React from 'react';
import PropTypes from 'prop-types';
import { HStack, Text } from '@chakra-ui/react';
import { DATE_MODE } from '../../../../slices/markets/marketsConfig';
import { RadioGroup, Radio } from '@/components/ui/radio';

export default function FilterDateType(props) {
  const { controlState, onDateModeChange } = props;

  const dateModeOptions = Object.entries(DATE_MODE).map(([key, value]) => ({
    label: key === 'DELIVERY' ? 'Deliveries' : 'Orders',
    value,
  }));

  return (
    <div>
      <Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        Showing
      </Text>
      <div>
        <RadioGroup value={controlState.dateMode} onValueChange={e => onDateModeChange(e.value)}>
          <HStack alignItems="center" justifyContent="end">
            {dateModeOptions.map(option => (
              <Radio key={option.value} value={option.value}>
                {option.label}
              </Radio>
            ))}
          </HStack>
        </RadioGroup>
      </div>
    </div>
  );
}

FilterDateType.propTypes = {
  controlState: PropTypes.shape({
    dateMode: PropTypes.string.isRequired,
  }).isRequired,
  onDateModeChange: PropTypes.func.isRequired,
};
