import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { HStack, Text } from '@chakra-ui/react';
import { MARKET_FOCUS } from '../../../../slices/markets/marketsConfig';
import { RadioGroup, Radio } from '@/components/ui/radio';

export default function FilterFocus(props) {
  const { activeFocus, onActiveFocusChange } = props;
  const name = useSelector(state => state.user.role.name);

  const label = name === 'ArbySonicDemo' ? 'Import' : 'Export';

  return (
    <div>
      <Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        Market
      </Text>
      <div>
        <RadioGroup value={activeFocus} onValueChange={e => onActiveFocusChange(e.value)}>
          <HStack alignItems="center" justifyContent="end">
            <Radio value={MARKET_FOCUS.DOMESTIC}>Domestic</Radio>
            <Radio value={MARKET_FOCUS.EXPORT}>{label}</Radio>
          </HStack>
        </RadioGroup>
      </div>
    </div>
  );
}

FilterFocus.propTypes = {
  activeFocus: PropTypes.string,
  onActiveFocusChange: PropTypes.func,
};
