import React from 'react';
import PropTypes from 'prop-types';
import { VStack } from '@chakra-ui/react';

const propTypes = {
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  style: PropTypes.objectOf(),
};

const Card = ({ children, style }) => {
  return (
    <VStack
      align="stretch"
      gap={5}
      margin={0}
      padding={5}
      backgroundColor="card.default"
      borderRadius={5}
      boxShadow="md"
      style={style}
    >
      {children}
    </VStack>
  );
};

Card.propTypes = propTypes;

export default Card;
