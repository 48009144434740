import React from 'react';
import { FREQUENCY } from '../../../../../slices/quality/qualityConfig';
import styles from '../Filter.module.css';
import { RadioGroup, Radio } from '@/components/ui/radio';
import { HStack, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export default function FilterFrequency({ value, onChange }) {
  return (
    <div>
      <Text className={styles.filterLabel}>Frequency</Text>
      <RadioGroup
        onValueChange={e => onChange(e.value)}
        defaultValue={value}
        className={styles.radioGroup}
        style={{ marginTop: 5 }}
      >
        <HStack alignItems="center" justifyContent="end">
          <Radio key={FREQUENCY.MONTHLY} value={FREQUENCY.MONTHLY}>
            M
          </Radio>
          <Radio key={FREQUENCY.ANNUALLY} value={FREQUENCY.ANNUALLY}>
            Y
          </Radio>
        </HStack>
      </RadioGroup>
    </div>
  );
}

FilterFrequency.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
