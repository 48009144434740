import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router';
import ArticleCardList from '../core/articles/ArticleCardList';
import ArticleSummaryList from '../core/articles/ArticleSummaryList';
import AuthGate from '../../containers/auth/AuthGate';
import IUnleashClassFlagProvider from '../unleash/UnleashClassFlagProvider';
import Constants from '../../Constants';
import { Heading, VStack, Box, Button } from '@chakra-ui/react';
import HorizontalNavigationBand from '../core/HorizontalNavigationBand';

const featureCount = 8;

const ArticleList = ({ articleList, getArticlesList }) => {
  useEffect(() => {
    getArticlesList();
  }, []);

  const featuredArticles = articleList.slice(0, featureCount);
  const moreArticles = articleList.slice(featureCount);
  return (
    <VStack background="white" align="stretch" gap="28px">
      <AuthGate requiredPermissions={['articles_write']}>
        <HorizontalNavigationBand>
          <Link to="/articles/edit/new">
            <Button colorScheme="actionSecondary" fontSize="16px" height="43px" fontWeight="600">
              Create New Article
            </Button>
          </Link>
        </HorizontalNavigationBand>
      </AuthGate>
      <VStack background="white" align="stretch" marginTop="28px" paddingX="62px" gap="6">
        <Heading fontSize="18px">Articles</Heading>
        {featuredArticles.length ? (
          <ArticleCardList articles={featuredArticles} />
        ) : (
          <Box style={{ textAlign: 'center', margin: ' 20vh auto' }}>
            <Box className="fa fa-inbox" style={{ backgroundColor: 'transparent', fontSize: '30px', color: 'grey' }} />
            <Box style={{ backgroundColor: 'transparent', fontSize: '15px', color: 'grey' }}>No Data</Box>
          </Box>
        )}
        <Heading fontSize="18px" marginTop="100px !important">
          More Articles
        </Heading>
        {moreArticles.length ? (
          <ArticleSummaryList articles={moreArticles} />
        ) : (
          <Box style={{ textAlign: 'center', margin: ' 20vh auto' }}>
            <Box className="fa fa-inbox" style={{ backgroundColor: 'transparent', fontSize: '30px', color: 'grey' }} />
            <Box style={{ backgroundColor: 'transparent', fontSize: '15px', color: 'grey' }}>No Data</Box>
          </Box>
        )}
      </VStack>
    </VStack>
  );
};

ArticleList.propTypes = {
  articleList: PropTypes.arrayOf(PropTypes.object),
  getArticlesList: PropTypes.func,
};

const ArticleListFeature = props => {
  return (
    <>
      <IUnleashClassFlagProvider
        flagName={Constants.UNLEASH_FLAG.ARTICLES}
        show={<ArticleList {...props} />}
        hide={<h1>Feature Not Enabled</h1>}
      />
    </>
  );
};

export default ArticleListFeature;
