import React, { useEffect, useState } from 'react';
import { Button, Grid, GridItem, Box } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useField } from 'formik';
import get from 'lodash/get';

import { downloadFromS3Link } from '../../../helpers/downloads';
import { formatLinkToDisplay } from '../../../utils';
import Loading from '../Loading';
import './FileUpload.scss';
import { MdDeleteOutline } from 'react-icons/md';

const FileUpload = ({ uploadFileToS3, resetUploadStatus, fieldName, uploadedState }) => {
  const [loading, setLoading] = useState(false);
  const [field, meta, helpers] = useField(fieldName);
  const { setValue } = helpers;

  // Similar to componentDidMount and componentDidUpdate:
  useEffect(() => {
    const { uploadSuccess, uploadedFileUrl, uploadSaved } = uploadedState;
    setLoading(false);
    if (!uploadSuccess && uploadSuccess !== undefined && !uploadSaved) {
      const attachments = [...field.value];
      attachments.pop();
      setValue(attachments);
    } else if (uploadedFileUrl !== undefined) {
      get(field, 'value') ? setValue([...field.value, uploadedFileUrl]) : setValue([uploadedFileUrl]);
      resetUploadStatus();
    }
  }, [get(uploadedState, 'uploadedFileUrl')]);

  const onFieldChange = e => {
    setLoading(true);
    uploadFileToS3({ file: e.target.files[0] });
  };

  const removeAttachment = idx => {
    if (field.value) {
      const attachments = [...field.value];
      attachments.splice(idx, 1);
      setValue(attachments);
    }
  };

  return (
    <div className="file-upload col-md-12">
      <div>
        <Box>
          <Box>
            <label className="btn btn-sm btn-info btn-file">
              Upload Files
              <input type="file" style={{ display: 'none' }} onChange={onFieldChange} />
            </label>
          </Box>
        </Box>
        {loading ? (
          <Loading />
        ) : (
          field.value &&
          field.value.length &&
          field.value.map((attachment, idx) => {
            return (
              <Grid templateColumns="repeat(24, 1fr)" gap={2} key={attachment}>
                <GridItem className="gutter-row" colSpan={6}>
                  <Button type="link" onClick={() => downloadFromS3Link(attachment)}>
                    <span className="file-upload__anchor-link">{formatLinkToDisplay(attachment)}</span>
                  </Button>
                </GridItem>
                <GridItem className="gutter-row" colSpan={6}>
                  <Button className="file-upload__trash-btn" onClick={() => removeAttachment(idx)}>
                    <MdDeleteOutline size={20} />
                  </Button>
                </GridItem>
              </Grid>
            );
          })
        )}
      </div>
    </div>
  );
};

export default FileUpload;

FileUpload.propTypes = {
  uploadFileToS3: PropTypes.func.isRequired,
  resetUploadStatus: PropTypes.func.isRequired,
  fieldName: PropTypes.string.isRequired,
  uploadedState: PropTypes.shape({
    uploadSuccess: PropTypes.bool,
    uploadedFileUrl: PropTypes.string,
    uploadSaved: PropTypes.bool,
  }),
};
