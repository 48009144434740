import React from 'react';
import {
  endOfMonth,
  endOfQuarter,
  endOfWeek,
  endOfYear,
  startOfMonth,
  startOfQuarter,
  startOfWeek,
  startOfYear,
  subDays,
} from 'date-fns';
import moment from 'moment';
import { RadioGroup, Radio } from '@/components/ui/radio';
import { HStack, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import DatetimeRangePicker from 'react-datetime-range-picker';

export default function FilterTimePeriod(props) {
  const { timePeriod, onTimePeriodChange } = props;

  const [type, setType] = React.useState(undefined);

  const lastWeekStart = startOfWeek(subDays(new Date(), 7)).getTime();
  const lastWeekEnd = endOfWeek(subDays(new Date(), 7)).getTime();

  const thisWeekStart = startOfWeek(new Date()).getTime();
  const thisWeekEnd = endOfWeek(new Date()).getTime();

  const thisMonthStart = startOfMonth(new Date()).getTime();
  const thisMonthEnd = endOfMonth(new Date()).getTime();

  const thisQuarterStart = startOfQuarter(new Date()).getTime();
  const thisQuarterEnd = endOfQuarter(new Date()).getTime();

  const thisYearStart = startOfYear(new Date()).getTime();
  const thisYearEnd = endOfYear(new Date()).getTime();

  return (
    <div>
      <Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        Time range
      </Text>
      <div>
        <RadioGroup value={type} onValueChange={e => setType(e.value)}>
          <HStack alignItems="center" justifyContent="end">
            <Radio value="last_week" onClick={() => onTimePeriodChange([lastWeekStart, lastWeekEnd])}>
              Last week
            </Radio>
            <Radio value="this_week" onClick={() => onTimePeriodChange([thisWeekStart, thisWeekEnd])}>
              This week
            </Radio>
            <Radio value="this_month" onClick={() => onTimePeriodChange([thisMonthStart, thisMonthEnd])}>
              Month
            </Radio>
            <Radio value="this_quarter" onClick={() => onTimePeriodChange([thisQuarterStart, thisQuarterEnd])}>
              Quarter
            </Radio>
            <Radio value="this_year" onClick={() => onTimePeriodChange([thisYearStart, thisYearEnd])}>
              Year
            </Radio>
          </HStack>
        </RadioGroup>
        {/* <DatePicker.RangePicker
          picker="week"
          value={timePeriod.map(d => moment(d))}
          format={m => {
            return m.format('DD MMM YYYY');
          }}
          onChange={newPeriod => {
            if (!newPeriod) return;
            onTimePeriodChange(newPeriod.map(m => m.set({ hour: 23, minute: 59, second: 59 }).toDate().getTime()));
          }}
        />onTimePeriodChange(newPeriod.map(m => m.set({ hour: 23, minute: 59, second: 59 }).toDate().getTime())); */}

        <DatetimeRangePicker
          className="date-range-picker"
          inputProps={{
            placeholder: 'Date range',
          }}
          closeOnSelect
          startDate={moment(timePeriod[0]).toDate()}
          endDate={moment(timePeriod[1]).toDate()}
          onChange={e => {
            onTimePeriodChange([moment(e.startDate).toDate().getTime(), moment(e.endDate).toDate().getTime()]);
          }}
          timeFormat={false}
          dateFormat="DD/MM/YYYY"
        />
      </div>
    </div>
  );
}

FilterTimePeriod.propTypes = {
  timePeriod: PropTypes.arrayOf(PropTypes.number).isRequired,
  onTimePeriodChange: PropTypes.func.isRequired,
};
