import React from 'react';
import moment from 'moment';
import DatePicker from '@/components/core/DatePicker';
import { endOfMonth, endOfYear } from 'date-fns';
import { TIME_SCALES } from '../../../../slices/quality/qualityConfig';
import styles from './FilterTimePeriod.module.css';
import PropTypes from 'prop-types';

export default function FilterTimePeriod(props) {
  const { value, onChange, timeScale } = props;

  return (
    <div>
      <DatePicker
        className={styles.QualityFilterTimePeriod_datePicker}
        {...(timeScale === TIME_SCALES.ANNUALLY ? { showYearPicker: true } : { showMonthYearPicker: true })}
        value={moment(value[0])}
        allowClear={false}
        // format={m => {
        //   if (timeScale === TIME_SCALES.ANNUALLY) {
        //     return m.format('YYYY');
        //   } else {
        //     return m.format('MMMM, YYYY');
        //   }
        // }}
        // disabledDate={current => {
        //   return current && current > moment().subtract(1, 'month');
        // }}
        minDate={new Date()}
        onChange={newPeriod => {
          if (!newPeriod) return;
          const momentDate = moment(newPeriod);
          const startOfPeriod = momentDate.toDate().getTime();
          if (timeScale === TIME_SCALES.ANNUALLY) {
            onChange([startOfPeriod, endOfYear(startOfPeriod)]);
          } else {
            onChange([startOfPeriod, endOfMonth(startOfPeriod)]);
          }
        }}
      />
    </div>
  );
}

FilterTimePeriod.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number).isRequired,
  onChange: PropTypes.func.isRequired,
  timeScale: PropTypes.string.isRequired,
};
