import React from 'react';
import { useSelector } from 'react-redux';
import { mean } from 'd3';
import { formatPercent, formatDollars } from '../../../../modules/format';
import { selectTreasuryTableData } from '../../../../slices/treasury/selectors/selectTreasuryTableData';
import { XAxisLine, TickYLines, TickYValues } from '../BaseChart/BaseChart';
import styles from './DataTable.module.css';
import { RadioGroup, Radio } from '@/components/ui/radio';
import { HStack, Collapsible } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export default function DataTable({ chartProps, multiOption }) {
  const { scales } = chartProps;

  const tableData = useSelector(selectTreasuryTableData);
  const [tableType, setTableType] = React.useState('hedged');

  const data = [];
  tableData.timeGroups.forEach(y => {
    data.push(...y.intervals);
  });

  const averageData = tableData.timeGroups.map(r => {
    const average = mean(r.intervals, int => int.dataBreakdown.volumeHedgedRatio);
    return {
      year: r.year,
      intervalsInYear: r.intervals.length,
      average,
    };
  });

  const title = multiOption ? 'Aggregate risk mgmt. data' : '% Hedged';

  if (averageData) {
    return (
      <div style={{ position: 'relative' }}>
        <Collapsible.Root>
          {multiOption && (
            <div style={{ position: 'absolute', top: 10, left: 230, zIndex: 1 }}>
              <RadioGroup size="sm" onValueChange={e => setTableType(e.value)} value={tableType}>
                <HStack alignItems="center" justifyContent="end">
                  <Radio value="hedged" fontSize="11px">
                    % Hedged
                  </Radio>
                  <Radio value="market-hedged" fontSize="11px">
                    Avg. Market Px v Hedged rate
                  </Radio>
                  <Radio value="notional-mtm" fontSize="11px">
                    Notional Amt. v MTM
                  </Radio>
                </HStack>
              </RadioGroup>
            </div>
          )}
          <Collapsible.Content key={1}>
            <Collapsible.Trigger>{title}</Collapsible.Trigger>
            <div className={styles.rowContainer} style={{ height: tableType === 'hedged' ? 40 : 50 }}>
              {tableType === 'market-hedged' && <div className={styles.tableTitle}>Avg. Market Px</div>}
              {tableType === 'notional-mtm' && <div className={styles.tableTitle}>Notional Amt.</div>}
              {scales &&
                (tableType === 'hedged' ? (
                  <HedgedYearData {...chartProps} data={data} averageData={averageData} />
                ) : (
                  <HedgedIntervalData
                    {...chartProps}
                    data={data}
                    dataKey={
                      tableType === 'market-hedged'
                        ? 'avgMarketPrice'
                        : tableType === 'notional-mtm'
                        ? 'notionalAmt'
                        : undefined
                    }
                    format={
                      tableType === 'market-hedged'
                        ? formatDollars(2)
                        : tableType === 'notional-mtm'
                        ? formatDollars(0)
                        : undefined
                    }
                  />
                ))}
            </div>
            <div className={styles.rowContainer} style={{ height: tableType === 'hedged' ? 40 : 50 }}>
              {tableType === 'market-hedged' && <div className={styles.tableTitle}>Hedged rate</div>}
              {tableType === 'notional-mtm' && <div className={styles.tableTitle}>Mark-to-Market</div>}
              {scales && (
                <HedgedIntervalData
                  {...chartProps}
                  data={data}
                  dataKey={
                    tableType === 'market-hedged'
                      ? 'effectiveHedgedRate'
                      : tableType === 'notional-mtm'
                      ? 'markToMarket'
                      : 'volumeHedgedRatio'
                  }
                  format={
                    tableType === 'market-hedged'
                      ? formatDollars(2)
                      : tableType === 'notional-mtm'
                      ? formatDollars(0)
                      : formatPercent(2)
                  }
                />
              )}
            </div>
            <div className={styles.YAxis}>
              {scales && (
                <svg>
                  <XAxisLine {...chartProps} barData={data} yPosition={0} bold />
                  <TickYLines {...chartProps} barData={data} yPosition={0} />
                </svg>
              )}
            </div>
            <div className={styles.rowContainer} style={{ marginTop: '5px' }}>
              {scales && <TickYValues {...chartProps} barData={data} />}
            </div>
          </Collapsible.Content>
        </Collapsible.Root>
      </div>
    );
  }
  return <div />;
}

DataTable.propTypes = {
  chartProps: PropTypes.shape({
    scales: PropTypes.shape({
      x: PropTypes.func.isRequired,
      y: PropTypes.func.isRequired,
    }).isRequired,
  }).isRequired,
  multiOption: PropTypes.bool,
};

function HedgedYearData({ scales, data, averageData, itemWidth, splitIndex }) {
  const averageLookup = interval => {
    const year = interval.split(' ')[1];
    const yearData = averageData.find(x => x.year.toString() === year);
    return yearData ? yearData.average : 0;
  };
  return (
    <>
      {data &&
        data.map((d, i) => {
          const offset = i >= splitIndex && splitIndex !== -1 ? 20 : 0;
          return (
            <div
              key={`hedgedInterval-${Math.random()}`}
              className={styles.hedgedData}
              style={{
                left: scales.x(d.interval) + offset,
                width: d.interval.split(' ')[0] === 'Q4' ? itemWidth - 2 : itemWidth,
                borderTop: `10px solid ${getCoverageColor(averageLookup(d.interval))}`,
              }}
            >
              {d.interval.split(' ')[0] === 'Q1' ? formatPercent(2)(averageLookup(d.interval)) : ''}
            </div>
          );
        })}
    </>
  );
}

function HedgedIntervalData({ scales, data, dataKey, format, itemWidth, splitIndex }) {
  return (
    <>
      {data &&
        data.map((d, i) => {
          const offset = i >= splitIndex && splitIndex !== -1 ? 20 : 0;
          const val = d.dataBreakdown[dataKey];
          return (
            <div
              key={`hedgedInterval-${Math.random()}`}
              className={styles.hedgedData}
              style={{
                left: scales.x(d.interval) + 1 + offset,
                width: itemWidth - 2,
                borderTop: `10px solid ${getCoverageColor(val)}`,
              }}
            >
              {format(val)}
            </div>
          );
        })}
    </>
  );
}

const getCoverageColor = percent => {
  if (percent > 0.2) {
    return '#E6E6E6';
  }
  if (percent > 0.15) {
    return '#C4C4C4';
  }
  if (percent > 0.1) {
    return '#A3A3A3';
  }
  if (percent > 0.07) {
    return '#828282';
  }
  if (percent > 0.03) {
    return '#656565';
  }
  return '#E9E9E9';
};
