import React from 'react';
import PropTypes from 'prop-types';
import { HStack } from '@chakra-ui/react';
import { TIME_SCALES } from '../../../../slices/quality/qualityConfig';
import styles from './FilterTimeScale.module.css';
import { RadioGroup, Radio } from '@/components/ui/radio';

// todo :
// 1. Show how the data could be structured, and how it refetches as filters change

export default function FilterTimeScale({ value, onChange }) {
  return (
    <RadioGroup
      onValueChange={e => onChange(e.value)}
      defaultValue={value}
      className={styles.FilterTimeScale_radioGroup}
    >
      <HStack alignItems="center" justifyContent="end">
        <Radio key={TIME_SCALES.MONTHLY} value={TIME_SCALES.MONTHLY}>
          {TIME_SCALES.MONTHLY}
        </Radio>
      </HStack>
    </RadioGroup>
  );
}

FilterTimeScale.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
