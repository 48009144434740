import React, { useEffect } from 'react';
import { differenceInDays, eachDayOfInterval, eachWeekOfInterval, format, getDay } from 'date-fns';
import { HStack, Text, Grid, GridItem } from '@chakra-ui/react';
import PropTypes from 'prop-types';

import { formatPercent, formatSI } from '../../../../modules/format';
import BaseChart from '../../treasury/BaseChart/BaseChart';
import { WEIGHT_UNIT } from '../../../../modules/const';
import styles from './VolumeChart.module.css';
import { MAX_DAYS_TO_SHOW_DATA_DAILY } from '../../../../slices/markets/selectors/selectMarketsSupplyChainData';
import { Checkbox } from '@/components/ui/checkbox';
import { RadioGroup, Radio } from '@/components/ui/radio';

const colors = [
  '#1CA6B2',
  '#F01D82',
  '#805CFF',
  '#F2C94C',
  '#00B5F2',
  '#FC5D36',
  '#006df2',
  '#FF9A3D',
  '#545B72',
  '#AB2424',
  '#029916',
];

const breakdownArrayToObject = (breakdown, volumeType) =>
  breakdown.reduce(
    (acc, n) => ({
      ...acc,
      [n.orderEntity]: volumeType === 'unit' ? n.volume : n.volumeRatio,
    }),
    {}
  );

export default function VolumeChart(props) {
  const { marketSupplyChainVolumeData, timePeriod, entityList, activeMarket } = props;

  const [volumeType, setVolumeType] = React.useState('unit');
  const [entities, setEntities] = React.useState(entityList.entities);

  useEffect(() => {
    setEntities(entityList.entities);
  }, [entityList]);

  const dateStart = new Date(timePeriod[0]);
  const dateEnd = new Date(timePeriod[1]);

  const dayDiff = differenceInDays(timePeriod[1], timePeriod[0]);

  const periodDays = eachDayOfInterval({
    start: dateStart,
    end: dateEnd,
  }).map(x => format(x, 'dd/MM'));

  const dayOfWeekInteger = getDay(dateStart);

  const periodWeeks = eachWeekOfInterval(
    {
      start: dateStart,
      end: dateEnd,
    },
    {
      weekStartsOn: dayOfWeekInteger,
    }
  ).map(x => format(x, 'dd/MM/yy'));

  const barData = marketSupplyChainVolumeData.intervals.map(({ interval, orders }) => {
    return {
      interval,
      ...breakdownArrayToObject(orders, volumeType),
    };
  });

  const data = (dayDiff < MAX_DAYS_TO_SHOW_DATA_DAILY ? periodDays : periodWeeks).map(x => {
    const intervalData = barData.find(y => y.interval === x);
    if (intervalData) {
      return {
        ...intervalData,
      };
    }
    return {
      interval: x,
    };
  });

  entities.forEach((x, i) => {
    x.color = colors[i % colors.length];
  });

  return (
    <Grid templateColumns="repeat(24, 1fr)" style={{ marginTop: 20 }}>
      <GridItem colSpan={19}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '20px',
            paddingRight: 30,
          }}
        >
          <div>
            <strong>Volume</strong>
            <RadioGroup
              value={volumeType}
              size="small"
              style={{ marginLeft: 10 }}
              onValueChange={e => setVolumeType(e.value)}
            >
              <HStack alignItems="center" justifyContent="end">
                <Radio value="unit">{activeMarket.quantityUnit === WEIGHT_UNIT.KILOGRAM ? 'kg' : 'lb'}</Radio>
                <Radio value="percent">%</Radio>
              </HStack>
            </RadioGroup>
          </div>
          <div>
            <span style={{ textTransform: 'uppercase' }}>Total</span>{' '}
            <span style={{ fontSize: 24, fontWeight: 'bold' }}>
              {formatSI(2)(marketSupplyChainVolumeData.ordersTotal)}
            </span>{' '}
            <span>{activeMarket.quantityUnit === WEIGHT_UNIT.KILOGRAM ? 'kg' : 'lb'}</span>
          </div>
        </div>
        <BaseChart
          barData={data}
          splitIndex={-1}
          colors={entities.reduce(
            (acc, n) => ({
              ...acc,
              [n.label]: n.color,
            }),
            {}
          )}
          columns={entities.filter(x => x.isShown).map(x => x.label)}
          formatValues={volumeType === 'unit' ? formatSI(0) : formatPercent(0)}
          noCurrentLine
          noLegend
          minMaxGenerator={maxData => ({
            maxVal: maxData,
            minVal: 0,
          })}
          ticksNumber={6}
          // AnnotationLayer={(p) => {
          //   if (!p.scales) return <div />;
          //   return (
          //     <AnnotationLayer
          //       data={p.barData}
          //       xScale={p.scales.x}
          //       splitIndex={p.splitIndex}
          //       addNewAnnotation={addNewAnnotation}
          //       editAnnotation={editAnnotation}
          //       moduleAnnotations={moduleAnnotations}
          //       getXValue={(d) => d.interval + ' ' + d.year}
          //     />
          //   );
          // }}
        />
      </GridItem>
      <GridItem colSpan={5}>
        <EntitySelector entities={entities} setEntities={setEntities} type={entityList.type} />
        <div>
          <Text
            style={{
              textTransform: 'uppercase',
              marginBottom: '5px',
              color: '#0B1435',
              opacity: 0.6,
            }}
          >
            Annotations
          </Text>
        </div>
      </GridItem>
    </Grid>
  );
}

VolumeChart.propTypes = {
  marketSupplyChainVolumeData: PropTypes.shape({
    intervals: PropTypes.arrayOf(
      PropTypes.shape({
        interval: PropTypes.string.isRequired,
        orders: PropTypes.arrayOf(
          PropTypes.shape({
            orderEntity: PropTypes.string.isRequired,
            volume: PropTypes.number.isRequired,
            volumeRatio: PropTypes.number.isRequired,
          })
        ).isRequired,
      })
    ).isRequired,
    ordersTotal: PropTypes.number.isRequired,
  }),
  timePeriod: PropTypes.arrayOf(PropTypes.number).isRequired,
  entityList: PropTypes.shape({
    entities: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string.isRequired,
        isShown: PropTypes.bool.isRequired,
        color: PropTypes.string.isRequired,
      })
    ).isRequired,
    type: PropTypes.string.isRequired,
  }).isRequired,
  activeMarket: PropTypes.shape({
    quantityUnit: PropTypes.string.isRequired,
  }).isRequired,
};

function EntitySelector(props) {
  const { entities, setEntities, type } = props;
  return (
    <div>
      <Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        {type}
      </Text>
      <div style={{ margin: '10px 0 20px' }}>
        {entities.map(s => {
          return (
            <Checkbox
              key={s.label}
              disabled={entities.filter(x => x.isShown).length === 1 && s.isShown}
              className={styles[`checkboxEntity-${s.color.replaceAll('#', '')}`]}
              onChange={() => {
                const tmp = entities.map(x => {
                  if (x.label === s.label) {
                    return { label: x.label, isShown: !x.isShown };
                  }
                  return x;
                });
                setEntities(tmp);
              }}
              checked={s.isShown}
              style={{ width: '100%', marginLeft: 0 }}
            >
              {s.label}
            </Checkbox>
          );
        })}
      </div>
    </div>
  );
}

EntitySelector.propTypes = {
  entities: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      isShown: PropTypes.bool.isRequired,
      color: PropTypes.string.isRequired,
    })
  ).isRequired,
  setEntities: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
};
