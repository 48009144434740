import React, { useState, useCallback, useEffect } from 'react';
import { Tree } from 'antd';
import { Link, useParams } from 'react-router';
import { descending } from 'd3';
import { format } from 'date-fns';
import { useDispatch } from 'react-redux';
import { HStack, Text, Icon } from '@chakra-ui/react';

import { formatChange, formatPercent, formatSI, singleMonthLetter } from '../../../../modules/format';
import BaseChart from '../../treasury/BaseChart/BaseChart';
import styles from './NCRs.module.css';
import { calculateRoundedMax } from '../../../../modules/data/rounded-max';
import yearOnYear, { getUniqueYears } from '../../../../modules/data/year-on-year';
import { ChevronDown, ChevronRight } from '../../../../shared/Icons';
import {
  CLAIM_DOLLARS_OPTIONS,
  INCIDENTS_OPTIONS,
  TIME_RANGE_OPTIONS,
  VOLUME_OPTIONS,
} from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsConfig';
import {
  setQualityNCRsBreakdownMode,
  setQualityNCRsClaimDollars,
  setQualityNCRsIncidents,
  setQualityNCRsRegion,
  setQualityNCRsMarketKey,
  setQualityNCRsSelectedPackerPlantId,
  setQualityNCRsTimeRange,
  setQualityNCRsVolume,
} from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsReducers';
import { Tooltip } from '@/components/ui/tooltip';
import { RadioGroup, Radio } from '@/components/ui/radio';

const Incidents = props => {
  const { ncrsData, singlePackerPlant, packerPlantsInPacker, selectedRegion, market } = props;
  const data = ncrsData.incidents;
  const countDifference = data.count - data.previousCount;
  const percentDifference = data.previousCount > 0 ? (countDifference / data.previousCount) * 100 : 100;
  const [dataType, setDataType] = useState('value');
  const { market: marketParam } = useParams();

  useEffect(() => {
    if (!(singlePackerPlant && singlePackerPlant.isPacker)) {
      setDataType('value');
    }
  }, [singlePackerPlant]);

  const dispatch = useDispatch();
  const handleNavigationToDetails = useCallback(() => {
    dispatch(setQualityNCRsIncidents(INCIDENTS_OPTIONS.TOTAL));
    dispatch(setQualityNCRsVolume(VOLUME_OPTIONS[market.key].PERCENT_OF_TOTAL));
    dispatch(setQualityNCRsClaimDollars(CLAIM_DOLLARS_OPTIONS.CURRENCY));
    dispatch(setQualityNCRsTimeRange(TIME_RANGE_OPTIONS.YOY));
    dispatch(setQualityNCRsBreakdownMode(null));
    dispatch(setQualityNCRsMarketKey(market.key));
    if (singlePackerPlant) {
      dispatch(setQualityNCRsSelectedPackerPlantId(singlePackerPlant.id));
      dispatch(setQualityNCRsRegion(singlePackerPlant.country));
    } else if (selectedRegion) {
      dispatch(setQualityNCRsRegion(selectedRegion));
    }
  }, [dispatch, selectedRegion, singlePackerPlant]);

  if (packerPlantsInPacker) {
    return (
      <PackerPerPackerPlantView
        {...props}
        marketParam={marketParam}
        handleNavigationToDetails={handleNavigationToDetails}
      />
    );
  }

  return (
    <div>
      <Text className={styles.QualityNcrs_totalIncidents}>
        <Link
          style={{ color: 'black' }}
          to={`/analytic-dashboard/quality/${marketParam}/ncrs-details`}
          onClick={() => handleNavigationToDetails()}
        >
          <span>{data.count}</span> incidents{' '}
          <Icon style={{ position: 'relative', top: -3, width: 14 }}>
            <ChevronRight />
          </Icon>
        </Link>
      </Text>
      {countDifference !== 0 ? (
        <p className={styles.QualityNcrs_difference}>
          <span className={styles.QualityNcrs_differenceArrow}>{formatChange('negative')(percentDifference)}</span>
          <strong>{Math.abs(Math.round(percentDifference * 10) / 10)}%</strong> ({Math.abs(countDifference)}{' '}
          {`${countDifference < 0 ? 'fewer' : 'more'}`} issues) from the previous month
        </p>
      ) : (
        <p className={styles.QualityNcrs_difference}>same as previous month</p>
      )}
      {singlePackerPlant && singlePackerPlant.isPacker && (
        <div style={{ width: '100%', textAlign: 'right' }}>
          <RadioGroup
            onValueChange={e => {
              setDataType(e.value);
            }}
            defaultValue={dataType}
            size="medium"
            className={styles.QualityNcrs_radio}
          >
            <HStack alignItems="center" justifyContent="end">
              <Radio key={1} value="value">
                Count
              </Radio>
              <Radio key={2} value="percentOfTotal">
                % of total incidents
              </Radio>
            </HStack>
          </RadioGroup>
        </div>
      )}
      {data && (
        <BaseChart
          barData={yearOnYear(
            data.byPeriod.map(x => ({
              interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
              value: x[dataType],
            }))
          )}
          columns={getUniqueYears(data.byPeriod.map(x => format(new Date(x.date), 'MMM yyyy')))}
          colors={{ value: '#959BB5' }}
          isSmall
          lastBarColor="#4E5984"
          splitIndex={-1}
          noCurrentLine
          noLegend
          ticksNumber={4}
          formatValues={val => (dataType === 'percentOfTotal' ? formatPercent(2)(val) : formatSI(1)(val))}
          formatIntervals={singleMonthLetter}
          minMaxGenerator={(maxData, minData) => {
            return {
              maxVal: calculateRoundedMax(maxData) || 1,
              minVal: 0,
            };
          }}
          formatTooltip={val => (dataType === 'percentOfTotal' ? formatPercent(2)(val) : `${val} incidents`)}
          padding={{ right: 2 }}
          isLineChart
          isYearOnYear
        />
      )}
    </div>
  );
};

const getTreeData = (data, withClaimValue) => {
  const MAX_NUM_OF_BOTTOM_PACKER_PLANTS = 5;

  if (!data) {
    return [];
  }

  const maxCount = Math.max(...data.map(x => x.count));

  const row = (d, isSecondary, color) => {
    const bar = fieldName => {
      const withClaimValueFieldName = 'withClaimValue';
      if (withClaimValue) {
        return (
          <div style={{ display: 'flex', marginTop: 0 }}>
            <Tooltip content={`${d[withClaimValueFieldName]} with claim value`}>
              <div
                style={{
                  width: `${d[withClaimValueFieldName] === 0 ? 0 : (d[withClaimValueFieldName] * 100) / maxCount}%`,
                  height: 10,
                  backgroundColor: color,
                  backgroundImage: `repeating-linear-gradient(-45deg, transparent, transparent 2px, rgba(255,255,255,.5) 2px, rgba(255,255,255,.5) 4px)`,
                }}
              />
            </Tooltip>
            <Tooltip content={`${d[fieldName] - d[withClaimValueFieldName]} without claim value`}>
              <div
                style={{
                  width: `${
                    d[fieldName] - d[withClaimValueFieldName] === 0
                      ? 0
                      : ((d[fieldName] - d[withClaimValueFieldName]) * 100) / maxCount
                  }%`,
                  height: 10,
                  backgroundColor: color,
                  backgroundImage: `repeating-linear-gradient(-45deg, transparent, transparent 2px, rgba(255,255,255,.5) 2px, rgba(255,255,255,.5) 4px)`,
                  opacity: 0.5,
                }}
              />
            </Tooltip>
          </div>
        );
      } else {
        let title = '';
        title = `${d[fieldName]} incidents`;
        return (
          <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} content={title}>
            <div
              style={{
                width: `${d[fieldName] === 0 ? 0 : (d[fieldName] * 100) / maxCount}%`,
                height: 10,
                backgroundColor: color,
                backgroundImage: `repeating-linear-gradient(-45deg, transparent, transparent 2px, rgba(255,255,255,.5) 2px, rgba(255,255,255,.5) 4px)`,
                marginTop: 0,
                opacity: 1,
              }}
            />
          </Tooltip>
        );
      }
    };

    return (
      <div
        className={`${styles.QualityNcrs_tableRow} ${isSecondary ? styles.QualityNcrs_tableRowSecondary : ''}`}
        style={{ marginLeft: isSecondary ? -24 : 0 }}
      >
        <div
          style={{
            width: '35%',
            padding: '0 5px',
            paddingLeft: isSecondary ? 24 : 0,
          }}
        >
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {isSecondary ? d.category : d.packerPlantName}
          </div>
        </div>
        <div
          style={{
            width: '15%',
            padding: '0 5px',
            display: 'flex',
          }}
        >
          <div
            className={styles.QualityNcrs_tableRowValues}
            style={{
              width: '100%',
              fontWeight: !isSecondary && 'bold',
            }}
          >
            {d.count}
          </div>
        </div>
        <div
          style={{
            width: '50%',
            padding: '0 5px',
          }}
        >
          {bar('count')}
        </div>
      </div>
    );
  };

  return data
    .slice()
    .filter(x => x.count > 0)
    .sort((a, b) => descending(a.count, b.count))
    .slice(0, MAX_NUM_OF_BOTTOM_PACKER_PLANTS)
    .map((packerPlant, i) => {
      return {
        title: row(packerPlant, false, packerPlant.color || '#D3D5E0'),
        key: i + '-' + packerPlant.packerPlantId,
        children: packerPlant.byCategory?.map((category, j) => {
          return {
            title: row(category, true, 'rgba(11, 20, 53, 0.6)'),
            key: i + '-' + j + '-' + category.category,
          };
        }),
      };
    });
};

const PackerPerPackerPlantView = props => {
  const { ncrsData, singlePackerPlant, packerPlantsInPacker, marketParam, handleNavigationToDetails } = props;
  const data = ncrsData.incidents;
  const [withClaimValue, setWithClaimValue] = useState(false);

  return (
    <div>
      <Text className={styles.QualityNcrs_totalIncidents}>
        <Link
          to={`/analytic-dashboard/quality/${marketParam}/ncrs-details`}
          onClick={() => handleNavigationToDetails()}
        >
          <span>{data.count}</span> incidents{' '}
          <Icon style={{ position: 'relative', top: -3, width: 14 }}>
            <ChevronRight />
          </Icon>
        </Link>
      </Text>
      <div>
        <Text className={styles.QualityNcrs_label}>Highest number of incidents</Text>
      </div>
      {packerPlantsInPacker && (
        <Tree
          switcherIcon={
            <Icon style={{ opacity: 0.6 }}>
              <ChevronDown />
            </Icon>
          }
          selectable={false}
          treeData={getTreeData(packerPlantsInPacker, withClaimValue)}
          blockNode
        />
      )}
      <Link
        to={`/analytic-dashboard/quality/${marketParam}/ncrs-details`}
        onClick={() => handleNavigationToDetails()}
        className={styles.QualityNcrs_viewAll}
      >
        View all {singlePackerPlant.name} establishments
        <Icon>
          <ChevronRight />
        </Icon>
      </Link>
      {/* <div className={styles.QualityNcrs_byCategoryOptions}>
        <Checkbox checked={withClaimValue} onChange={() => setWithClaimValue(!withClaimValue)}>
          Compare with/without claim value
        </Checkbox>
      </div> */}
    </div>
  );
};

export default Incidents;
