import React, { Fragment } from 'react';
import { format, startOfMonth } from 'date-fns';
import { Text, Collapsible } from '@chakra-ui/react';
import styles from './Audit.module.css';
import { ReactComponent as Drop } from './drop.svg';
import { ReactComponent as Increase } from './increase.svg';
import { ReactComponent as Approved } from './approved.svg';
import { ReactComponent as Provisional } from './provisional.svg';
import { ReactComponent as ReAudit } from './reaudit.svg';
import { ReactComponent as Failed } from './failed.svg';
import { ReactComponent as Outstanding } from './outstanding.svg';
import { FaChevronDown } from 'react-icons/fa';

const Audit = props => {
  const { data, singlePackerPlant, timePeriod } = props;

  return (
    <div>
      {data.performed.length ? (
        <>
          <Text className={styles.Audit_auditSubtitle}>
            Overview: <span>{`${format(startOfMonth(timePeriod[0]), 'yyyy')}`}</span>
          </Text>
          {singlePackerPlant ? (
            <PerformedSinglePackerPlantTable data={data.performed} />
          ) : (
            <>
              <PerformedTable data={data.performed} swaAuditIncluded={!!data.swa} />
              {data.swa && (
                <>
                  <br />
                  <SWATable data={data.swa} />
                </>
              )}
            </>
          )}
          <Text className={styles.Audit_auditSubtitle}>
            Due: <span>Next 3 months</span>
          </Text>
          {singlePackerPlant ? (
            <UpcomingSinglePackerPlantTable data={data.upcoming} />
          ) : (
            <Collapsible.Root>
              {data.upcoming &&
                data.upcoming.map(x => {
                  return (
                    <Fragment key={x.type}>
                      <Collapsible.Trigger>
                        <FaChevronDown />
                      </Collapsible.Trigger>
                      <Collapsible.Content
                        className="smallPanel"
                        header={
                          <div className={styles.Audit_upcomingHeader}>
                            {x.type} <span>({x.byPackerPlant.length})</span>
                          </div>
                        }
                        key={`${x.type}-panel`}
                      >
                        <UpcomingTable data={x.byPackerPlant} type={x.type} />
                      </Collapsible.Content>
                    </Fragment>
                  );
                })}
            </Collapsible.Root>
          )}
        </>
      ) : (
        'The audit information is not available'
      )}
    </div>
  );
};

const PerformedTable = props => {
  const { data, swaAuditIncluded } = props;
  return (
    <div className={styles.Audit_performedTable}>
      <Text>
        <div className={styles.Audit_header}>
          <div style={{ width: '15%' }}>
            <div className={styles.Audit_title}>Audit type</div>
            <div className={styles.Audit_subtitle} />
          </div>
          <div style={{ width: '13%' }}>
            <div className={styles.Audit_title}>Completed</div>
            <div className={styles.Audit_subtitle}>
              <div style={{ width: '40%' }}>total</div>
              <div style={{ width: '60%' }}>on-time</div>
            </div>
          </div>
          <div style={{ width: '17%' }}>
            <div className={styles.Audit_title}>Performance</div>
            <div className={styles.Audit_subtitle}>
              <div style={{ width: '40%' }}>
                drop
                <Drop />
              </div>
              <div style={{ width: '60%' }}>
                increase
                <Increase />
              </div>
            </div>
          </div>
          <div style={{ width: '45%' }}>
            <div className={styles.Audit_title}>Score summary</div>
            <div className={styles.Audit_subtitle}>
              <div style={{ width: '25%' }}>
                approved
                <Approved />
              </div>
              <div style={{ width: '25%' }}>
                provisional
                <Provisional />
              </div>
              <div style={{ width: '25%' }}>
                failed
                <Failed />
              </div>
            </div>
          </div>
        </div>
      </Text>
      <div className={styles.Audit_body}>
        {data.map(x => {
          return (
            <div className={styles.Audit_row} key={x.type}>
              <div style={{ width: '15%' }}>
                <div className={styles.Audit_cell}>
                  <div
                    style={{
                      overflow: 'hidden',
                      whiteSpace: 'nowrap',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {x.type}
                  </div>
                </div>
              </div>
              <div style={{ width: '13%' }}>
                <div className={styles.Audit_cell}>
                  <div style={{ width: '40%' }}>{x.completed.total}</div>
                  <div style={{ width: '60%', fontWeight: 'bold' }}>{x.completed.onTime}</div>
                </div>
              </div>
              <div style={{ width: '17%' }}>
                <div className={styles.Audit_cell}>
                  <div style={{ width: '40%' }}>{x.performance.drop}</div>
                  <div style={{ width: '60%' }}>{x.performance.increase}</div>
                </div>
              </div>
              <div style={{ width: '45%' }}>
                <div className={styles.Audit_cell}>
                  <div
                    style={{
                      width: '25%',
                      backgroundColor: 'rgba(9, 175, 163, 0.1)',
                    }}
                  >
                    {x.scoreSummary.approved}
                  </div>
                  <div
                    style={{
                      width: '25%',
                      backgroundColor: 'rgba(254, 208, 0, 0.1)',
                    }}
                  >
                    {x.scoreSummary.provisional}
                  </div>
                  <div
                    style={{
                      width: '25%',
                      backgroundColor: 'rgba(255, 44, 27, 0.1)',
                    }}
                  >
                    {x.scoreSummary.failed}
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const SWATable = props => {
  const { data } = props;
  return (
    <div className={styles.Audit_performedTable}>
      <Text>
        <div className={styles.Audit_header}>
          <div style={{ width: '15%' }}>
            <div className={styles.Audit_title} />
            <div className={styles.Audit_subtitle} />
          </div>
          <div style={{ width: '30%' }}>
            <div className={styles.Audit_title}>Online self assessment</div>
            <div className={styles.Audit_subtitle}>
              <div style={{ width: '30%' }}>submitted</div>
              <div style={{ width: '30%' }}>on-time</div>
              <div style={{ width: '40%' }}>
                outstanding
                <Outstanding />
              </div>
            </div>
          </div>
          <div style={{ width: '45%' }}>
            <div className={styles.Audit_title}>On-site inspection</div>
            <div className={styles.Audit_subtitle}>
              <div style={{ width: '25%' }}>
                pass
                <Approved />
              </div>
              <div style={{ width: '25%' }}>
                provisional
                <Provisional />
              </div>
              <div style={{ width: '25%' }}>
                re-audit
                <ReAudit />
              </div>
              <div style={{ width: '25%' }}>
                failed
                <Failed />
              </div>
            </div>
          </div>
        </div>
      </Text>
      <div className={styles.Audit_body}>
        <div className={styles.Audit_row} key="swa">
          <div style={{ width: '15%' }}>
            <div className={styles.Audit_cell}>
              <div>{data.type}</div>
            </div>
          </div>
          <div style={{ width: '30%' }}>
            <div className={styles.Audit_cell}>
              <div style={{ width: '30%' }}>{data.selfAssessment.submitted}</div>
              <div style={{ width: '30%' }}>{data.selfAssessment.onTime}</div>
              <div style={{ width: '40%' }}>{data.selfAssessment.outstanding}</div>
            </div>
          </div>
          <div style={{ width: '45%' }}>
            <div className={styles.Audit_cell}>
              <div
                style={{
                  width: '25%',
                  backgroundColor: 'rgba(9, 175, 163, 0.1)',
                }}
              >
                {data.inspection.pass}
              </div>
              <div
                style={{
                  width: '25%',
                  backgroundColor: 'rgba(254, 208, 0, 0.1)',
                }}
              >
                {data.inspection.provisional}
              </div>
              <div
                style={{
                  width: '25%',
                  backgroundColor: 'rgba(255, 127, 0, 0.1)',
                }}
              >
                {data.inspection.reAudit}
              </div>
              <div
                style={{
                  width: '25%',
                  backgroundColor: 'rgba(255, 44, 27, 0.1)',
                }}
              >
                {data.inspection.failed}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const PerformedSinglePackerPlantTable = props => {
  const { data } = props;
  return (
    <div className={styles.Audit_performedTable}>
      <Text>
        <div className={styles.Audit_header}>
          <div style={{ width: '20%' }}>
            <div className={styles.Audit_title}>Audit type</div>
          </div>
          <div style={{ width: '7.5%' }}>
            <div className={styles.Audit_title} />
          </div>
          {/* <div style={{ width: '7.5%' }}>
            <div className={styles.Audit_title} />
          </div> */}
          <div style={{ width: '10%' }}>
            <div className={styles.Audit_title}>Completed</div>
          </div>
          <div style={{ width: '10%', opacity: 0.6 }}>
            <div className={styles.Audit_title}>Prev audit</div>
          </div>
          <div style={{ width: '5%' }}>
            <div className={styles.Audit_title} />
          </div>
          <div style={{ width: '10%' }}>
            <div className={styles.Audit_title}>Score</div>
          </div>
          <div style={{ width: '30%' }}>
            <div className={styles.Audit_title}>Comments</div>
          </div>
        </div>
      </Text>
      <div className={styles.Audit_body}>
        {data.map(x => {
          return (
            <div className={styles.Audit_row} key={`completed-${x.type}`}>
              <div style={{ width: '20%' }}>
                <div className={styles.Audit_cell} style={{ textAlign: 'left' }}>
                  <div>
                    {x.type}
                    {x.subType && `: ${x.subType}`}
                  </div>
                </div>
              </div>
              {/* <div style={{ width: '7.5%', opacity: 0.6 }}>
                <div className={styles.Audit_cell}>
                  <div>{x.announced ? 'Announced' : 'Unannounced'}</div>
                </div>
              </div> */}
              <div style={{ width: '7.5%', opacity: 0.6 }}>
                <div className={styles.Audit_cell}>
                  <div>{x.responsible}</div>
                </div>
              </div>
              <div style={{ width: '10%' }}>
                <div className={styles.Audit_cell}>
                  <div>{format(new Date(x.dateCompleted).getTime(), 'd MMM yyyy')}</div>
                </div>
              </div>
              <div style={{ width: '10%', opacity: 0.6 }}>
                <div className={styles.Audit_cell}>
                  <div>{x.datePreviousAudit && format(new Date(x.datePreviousAudit).getTime(), 'd MMM yyyy')}</div>
                </div>
              </div>
              <div style={{ width: '5%' }}>
                <div className={styles.Audit_cell}>
                  <div>{x.performance === 'drop' ? <Drop /> : x.performance === 'increase' ? <Increase /> : null}</div>
                </div>
              </div>
              <div style={{ width: '10%' }}>
                <div className={styles.Audit_cell}>
                  <div>{getScoreBadge(x.score)}</div>
                </div>
              </div>
              <div style={{ width: '30%' }}>
                <div className={styles.Audit_cell} style={{ textAlign: 'left' }}>
                  <div>{x.comments}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const UpcomingTable = props => {
  const { data, type } = props;
  return (
    <div className={styles.Audit_upcomingTable}>
      <Text>
        <div className={styles.Audit_header}>
          <div style={{ width: '25%' }}>
            <div className={styles.Audit_title}>establishments</div>
          </div>
          {/* <div style={{ width: '10%' }}>
            <div className={styles.Audit_title}></div>
          </div>
          <div style={{ width: '7%' }}>
            <div className={styles.Audit_title}></div>
          </div> */}
          <div style={{ width: '25%' }}>
            <div className={styles.Audit_title}>Due</div>
          </div>
          <div style={{ width: '25%', opacity: 0.6 }}>
            <div className={styles.Audit_title}>Prev audit</div>
          </div>
          <div style={{ width: '25%' }}>
            <div className={styles.Audit_title}>Prev score</div>
          </div>
          {/* <div style={{ width: '30%' }}>
            <div className={styles.Audit_title}>Comments</div>
          </div> */}
        </div>
      </Text>
      <div className={styles.Audit_body}>
        {data.map(x => {
          return (
            <div className={styles.Audit_row} key={`${x.packerPlantId}-${type}`}>
              <div style={{ width: '25%' }}>
                <div className={styles.Audit_cell} style={{ textAlign: 'left' }}>
                  <div>{x.packerPlantName}</div>
                </div>
              </div>
              {/* <div style={{ width: '10%', opacity: 0.6 }}>
                <div className={styles.Audit_cell}>
                  <div>{x.announced ? 'Announced' : 'Unannounced'}</div>
                </div>
              </div>
              <div style={{ width: '7%', opacity: 0.6 }}>
                <div className={styles.Audit_cell}>
                  <div>{x.responsible}</div>
                </div>
              </div> */}
              <div style={{ width: '25%' }}>
                <div className={styles.Audit_cell}>
                  <div>
                    {format(new Date(x.dateScheduledStart).getTime(), 'd MMM yyyy')}
                    {x.dateScheduledEnd && ` - ${format(new Date(x.dateScheduledEnd).getTime(), 'd MMM yyyy')}`}
                  </div>
                </div>
              </div>
              <div style={{ width: '25%', opacity: 0.6 }}>
                <div className={styles.Audit_cell}>
                  <div>{x.datePreviousAudit && format(new Date(x.datePreviousAudit).getTime(), 'd MMM yyyy')}</div>
                </div>
              </div>
              <div style={{ width: '25%' }}>
                <div className={styles.Audit_cell}>
                  <div>{getScoreBadge(x.scorePreviousAudit)}</div>
                </div>
              </div>
              {/* <div style={{ width: '30%' }}>
                <div className={styles.Audit_cell} style={{ textAlign: 'left' }}>
                  <div>{x.comments}</div>
                </div>
              </div> */}
            </div>
          );
        })}
      </div>
    </div>
  );
};

const UpcomingSinglePackerPlantTable = props => {
  const { data } = props;
  return (
    <div className={styles.Audit_upcomingTable}>
      <div className={styles.Audit_body}>
        {data.map(x => {
          return (
            <div className={styles.Audit_row} key={`completed-${x.type}`}>
              <div style={{ width: '20%' }}>
                <div className={styles.Audit_cell}>
                  <div>
                    {x.type}
                    {x.subType && `: ${x.subType}`}
                  </div>
                </div>
              </div>
              <div style={{ width: '20%', opacity: 0.6 }}>
                <div className={styles.Audit_cell}>
                  <div>
                    {format(new Date(x.dateScheduledStart).getTime(), 'd MMM yyyy')}
                    {x.dateScheduledEnd && ` - ${format(new Date(x.dateScheduledEnd).getTime(), 'd MMM yyyy')}`}
                  </div>
                </div>
              </div>
              <div style={{ width: '60%', opacity: 0.6 }}>
                <div className={styles.Audit_cell} />
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const getScoreBadge = score => {
  switch (score) {
    case 'grade A':
    case 'grade B':
    case 'rating E':
    case 'rating G':
    case 'pass':
      return <div className={styles.Audit_greenBadge}>{score}</div>;
    case 'grade C':
    case 'rating C':
    case 'category 2 failures':
    case 'provisional':
      return <div className={styles.Audit_yellowBadge}>{score}</div>;
    case 're-audit':
      return <div className={styles.Audit_amberBadge}>{score}</div>;
    case 'grade B':
    case 'rating F':
    case 'category 1 failures':
    case 'failed':
      return <div className={styles.Audit_redBadge}>{score}</div>;
    default:
      return <div className={styles.Audit_greyBadge}>{score}</div>;
  }
};

export default Audit;
