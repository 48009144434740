import * as React from 'react';
import { Tree } from 'antd';
import { format } from 'date-fns';
import { descending } from 'd3';
import { Link, useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { formatChange, formatMarketQuantity, formatPercent, singleMonthLetter } from '../../../../modules/format';
import BaseChart from '../../treasury/BaseChart/BaseChart';
import styles from './NCRs.module.css';
import { calculateRoundedMax } from '../../../../modules/data/rounded-max';

import { ChevronDown, ChevronRight } from '../../../../shared/Icons';
import yearOnYear, { getUniqueYears } from '../../../../modules/data/year-on-year';
import {
  setQualityNCRsSelectedPackerPlantId,
  setQualityNCRsRegion,
  setQualityNCRsIncidents,
  setQualityNCRsVolume,
  setQualityNCRsClaimDollars,
  setQualityNCRsTimeRange,
  setQualityNCRsBreakdownMode,
} from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsReducers';
import {
  INCIDENTS_OPTIONS,
  VOLUME_OPTIONS,
  CLAIM_DOLLARS_OPTIONS,
  TIME_RANGE_OPTIONS,
  WEIGHT_OPTIONS,
} from '../../../../slices/quality-details-ncrs/qualityDetailsNcrsConfig';
import { Tooltip } from '@/components/ui/tooltip';
import { RadioGroup, Radio } from '@/components/ui/radio';
import { HStack, Text, Icon } from '@chakra-ui/react';

const VolumeSupplied = props => {
  const { ncrsData, singlePackerPlant, packerPlantsInPacker, selectedRegion, market } = props;
  const data = ncrsData.volume;
  const countDifference = data.count - data.previousCount;
  const percentDifference = data.previousCount > 0 ? (countDifference / data.previousCount) * 100 : 100;

  const { market: marketParam } = useParams();

  const [dataType, setDataType] = React.useState('value');

  const dispatch = useDispatch();
  const handleNavigationToDetails = React.useCallback(() => {
    if (singlePackerPlant) {
      dispatch(setQualityNCRsSelectedPackerPlantId(singlePackerPlant.id));
      dispatch(setQualityNCRsRegion(singlePackerPlant.country));
    } else {
      dispatch(setQualityNCRsRegion(selectedRegion));
    }
    dispatch(setQualityNCRsIncidents(INCIDENTS_OPTIONS.TOTAL));
    dispatch(setQualityNCRsVolume(VOLUME_OPTIONS[market.key].PERCENT_OF_TOTAL));
    dispatch(setQualityNCRsClaimDollars(CLAIM_DOLLARS_OPTIONS.CURRENCY));
    dispatch(setQualityNCRsTimeRange(TIME_RANGE_OPTIONS.YOY));
    dispatch(setQualityNCRsBreakdownMode(null));
  }, [dispatch, selectedRegion, singlePackerPlant]);

  if (packerPlantsInPacker) {
    return <PackerPerPackerPlantView {...props} />;
  }

  return (
    <div className={styles.QualityNcrs_volumeSupplied}>
      <Text className={styles.QualityNcrs_totalIncidents}>
        <Link
          style={{ color: 'black' }}
          to={`/analytic-dashboard/quality/${marketParam}/ncrs-details`}
          onClick={() => handleNavigationToDetails()}
        >
          <span>{formatPercent(3)(data.percentageOfTotal)}</span> of FMG supplied volume{' '}
          <Icon style={{ position: 'relative', top: -3, width: 14 }}>
            <ChevronRight />
          </Icon>
        </Link>
      </Text>
      {countDifference !== 0 ? (
        <p className={styles.QualityNcrs_difference}>
          <span className={styles.QualityNcrs_differenceArrow}>{formatChange('negative')(percentDifference)}</span>
          <strong>{Math.abs(Math.round(percentDifference * 10) / 10)}%</strong> (
          {Math.abs(countDifference / 1000).toFixed(2)}
          {'K '} {`${countDifference < 0 ? 'fewer ' : 'additional '}`} {WEIGHT_OPTIONS[market.key]}) from previous month
        </p>
      ) : (
        <p className={styles.QualityNcrs_difference}>same as previous month</p>
      )}
      {singlePackerPlant && singlePackerPlant.isPacker && (
        <div style={{ width: '100%', textAlign: 'right' }}>
          <RadioGroup
            onValueChange={e => {
              setDataType(e.value);
            }}
            defaultValue={dataType}
            size="medium"
            className={styles.QualityNcrs_radio}
          >
            <HStack alignItems="center" justifyContent="end">
              <Radio key={1} value="value">
                % of {singlePackerPlant.name} volume
              </Radio>
              <Radio key={2} value="percentOfTotal">
                % of total volume
              </Radio>
            </HStack>
          </RadioGroup>
        </div>
      )}
      {data && (
        <BaseChart
          barData={yearOnYear(
            data.byPeriod.map(x => ({
              interval: format(new Date(x.date).getTime(), 'MMM yyyy'),
              value: x[dataType],
            }))
          )}
          columns={getUniqueYears(data.byPeriod.map(x => format(new Date(x.date), 'MMM yyyy')))}
          colors={{ value: '#959BB5' }}
          isSmall
          formatValues={v => formatPercent(2)(v).replace('%', '')}
          formatTooltip={val => `${formatPercent(2)(val)}`}
          formatIntervals={singleMonthLetter}
          lastBarColor="#4E5984"
          splitIndex={-1}
          noCurrentLine
          noLegend
          ticksNumber={3}
          minMaxGenerator={(maxData, minData) => {
            return {
              maxVal: calculateRoundedMax(maxData),
              minVal: 0,
            };
          }}
          isLineChart
          isYearOnYear
        />
      )}
    </div>
  );
};

const getTreeData = (data, withClaimValue, dataType, market) => {
  if (!data) {
    return [];
  }

  const maxCount = Math.max(...data.map(x => x[dataType]));

  const row = (d, isSecondary, color) => {
    const bar = fieldName => {
      const withClaimValueFieldName = `${fieldName}WithClaimValue`;
      if (withClaimValue) {
        return (
          <div style={{ display: 'flex', marginTop: 0 }}>
            <Tooltip
              content={`${
                dataType === 'volumePercent'
                  ? formatPercent(2)(d[withClaimValueFieldName])
                  : formatMarketQuantity(market)(d[withClaimValueFieldName])
              } with claim value`}
            >
              <div
                style={{
                  width: `${d[withClaimValueFieldName] === 0 ? 0 : (d[withClaimValueFieldName] * 100) / maxCount}%`,
                  height: 10,
                  backgroundColor: color,
                  backgroundImage: `repeating-linear-gradient(-45deg, transparent, transparent 2px, rgba(255,255,255,.5) 2px, rgba(255,255,255,.5) 4px)`,
                }}
              />
            </Tooltip>
            <Tooltip
              content={`${
                dataType === 'volumePercent'
                  ? formatPercent(2)(d[fieldName] - d[withClaimValueFieldName])
                  : formatMarketQuantity(market)(d[fieldName] - d[withClaimValueFieldName])
              } without claim value`}
            >
              <div
                style={{
                  width: `${
                    d[fieldName] - d[withClaimValueFieldName] === 0
                      ? 0
                      : ((d[fieldName] - d[withClaimValueFieldName]) * 100) / maxCount
                  }%`,
                  height: 10,
                  backgroundColor: color,
                  backgroundImage: `repeating-linear-gradient(-45deg, transparent, transparent 2px, rgba(255,255,255,.5) 2px, rgba(255,255,255,.5) 4px)`,
                  opacity: 0.5,
                }}
              />
            </Tooltip>
          </div>
        );
      }

      let title = '';
      title = `${
        dataType === 'volumePercent' ? formatPercent(2)(d[dataType]) : formatMarketQuantity(market)(d[dataType])
      }`;
      return (
        <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} content={title}>
          <div
            style={{
              width: `${d[fieldName] === 0 ? 0 : (d[fieldName] * 100) / maxCount}%`,
              height: 10,
              backgroundColor: color,
              backgroundImage: `repeating-linear-gradient(-45deg, transparent, transparent 2px, rgba(255,255,255,.5) 2px, rgba(255,255,255,.5) 4px)`,
              marginTop: 0,
              opacity: 1,
            }}
          />
        </Tooltip>
      );
    };

    return (
      <div
        className={`${styles.QualityNcrs_tableRow} ${isSecondary ? styles.QualityNcrs_tableRowSecondary : ''}`}
        style={{ marginLeft: isSecondary ? -24 : 0 }}
      >
        <div
          style={{
            width: '35%',
            padding: '0 5px',
            paddingLeft: isSecondary ? 24 : 0,
          }}
        >
          <div
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
            }}
          >
            {isSecondary ? d.category : d.packerPlantName}
          </div>
        </div>
        <div
          style={{
            width: '15%',
            padding: '0 5px',
            display: 'flex',
          }}
        >
          <div
            className={styles.QualityNcrs_tableRowValues}
            style={{
              width: '100%',
              fontWeight: !isSecondary && 'bold',
            }}
          >
            {dataType === 'volumePercent' ? formatPercent(2)(d[dataType]) : formatMarketQuantity(market)(d[dataType])}
          </div>
        </div>
        <div
          style={{
            width: '50%',
            padding: '0 5px',
          }}
        >
          {bar(dataType)}
        </div>
      </div>
    );
  };

  return data
    .slice()
    .sort((a, b) => descending(a[dataType], b[dataType]))
    .slice(0, 5)
    .map((packerPlant, i) => {
      return {
        title: row(packerPlant, false, packerPlant.color || '#D3D5E0'),
        key: `${i}-${packerPlant.packerPlantId}`,
        children: packerPlant.byCategory?.map((category, j) => {
          return {
            title: row(category, true, 'rgba(11, 20, 53, 0.6)'),
            key: `${i}-${j}-${category.category}`,
          };
        }),
      };
    });
};

const PackerPerPackerPlantView = props => {
  const { ncrsData, singlePackerPlant, packerPlantsInPacker, selectedRegion, market } = props;
  const data = ncrsData.volume;
  const [withClaimValue, setWithClaimValue] = React.useState(false);
  const [dataType, setDataType] = React.useState('volumePercent');
  const { market: marketParam } = useParams();
  const dispatch = useDispatch();
  const handleNavigationToDetails = React.useCallback(() => {
    if (singlePackerPlant) {
      dispatch(setQualityNCRsSelectedPackerPlantId(singlePackerPlant.id));
      dispatch(setQualityNCRsRegion(singlePackerPlant.country));
    } else {
      dispatch(setQualityNCRsRegion(selectedRegion));
    }
    dispatch(setQualityNCRsIncidents(INCIDENTS_OPTIONS.TOTAL));
    dispatch(setQualityNCRsVolume(VOLUME_OPTIONS[market.key].PERCENT_OF_TOTAL));
    dispatch(setQualityNCRsClaimDollars(CLAIM_DOLLARS_OPTIONS.CURRENCY));
    dispatch(setQualityNCRsTimeRange(TIME_RANGE_OPTIONS.YOY));
    dispatch(setQualityNCRsBreakdownMode(null));
  }, [dispatch, selectedRegion, singlePackerPlant]);

  return (
    <div>
      <Text className={styles.QualityNcrs_totalIncidents}>
        <a>
          <span>{formatPercent(2)(data.percentageOfTotal)}</span> of {singlePackerPlant.name} supplied volume{' '}
          <Icon style={{ position: 'relative', top: -3, width: 14 }}>
            <ChevronRight />
          </Icon>
        </a>
      </Text>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text className={styles.QualityNcrs_label}>Largest impacted volume</Text>
        <div>
          <RadioGroup
            onValueChange={e => {
              setDataType(e.value);
            }}
            defaultValue={dataType}
            size="medium"
            className={styles.QualityNcrs_radio}
          >
            <HStack alignItems="center" justifyContent="end">
              <Radio key={1} value="volumePercent">
                % of {singlePackerPlant.name} volume
              </Radio>
              <Radio key={2} value="volume">
                kg
              </Radio>
            </HStack>
          </RadioGroup>
        </div>
      </div>
      {packerPlantsInPacker && (
        <Tree
          switcherIcon={
            <Icon style={{ opacity: 0.6 }}>
              <ChevronDown />
            </Icon>
          }
          selectable={false}
          treeData={getTreeData(packerPlantsInPacker, withClaimValue, dataType, market)}
          blockNode
        />
      )}
      <Link
        style={{ color: 'black' }}
        to={`/analytic-dashboard/quality/${marketParam}/ncrs-details`}
        onClick={() => handleNavigationToDetails()}
        className={styles.QualityNcrs_viewAll}
      >
        View all {singlePackerPlant.name} establishments
        <Icon style={{ position: 'relative', width: 14, top: 1 }}>
          <ChevronRight />
        </Icon>
      </Link>
      {/* <div className={styles.QualityNcrs_byCategoryOptions}>
        <Checkbox checked={withClaimValue} onChange={() => setWithClaimValue(!withClaimValue)}>
          Compare with/without claim value
        </Checkbox>
      </div> */}
    </div>
  );
};

export default VolumeSupplied;
