import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { HStack, Text } from '@chakra-ui/react';
import styles from '../Filter.module.css';
import { RadioGroup, Radio } from '@/components/ui/radio';

export default function FilterRadioGeneric(props) {
  const { label, value, onChange, options, withColors, dataColors, resetOnClick } = props;

  const handleClick = React.useCallback(
    x => {
      if (options) {
        if (x.target.className !== 'ant-radio-button-input' && x.target.className !== 'ant-radio-button-wrapper') {
          onChange(options);
        }
      }
    },
    [options]
  );

  useEffect(() => {
    if (resetOnClick) {
      window.addEventListener('click', handleClick);
      return function cleanup() {
        window.removeEventListener('click', handleClick);
      };
    }
  }, [resetOnClick, handleClick]);

  return (
    <div data-vertical data-all-selected={value === undefined}>
      {label && <Text className={styles.filterLabel}>{label}</Text>}
      <RadioGroup onValueChange={e => onChange([e.value])} value={value} className={styles.radioGroup}>
        <HStack alignItems="center" justifyContent="end">
          {options.map(x => (
            <Radio key={x} value={x}>
              {withColors && dataColors && <div className={styles.colorIndicator} data-color={dataColors[x]} />}
              {x}
            </Radio>
          ))}
        </HStack>
      </RadioGroup>
    </div>
  );
}

FilterRadioGeneric.propTypes = {
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  withColors: PropTypes.bool,
  dataColors: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  resetOnClick: PropTypes.bool,
};
