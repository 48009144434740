import React from 'react';
import { Tree } from 'antd';
import { descending } from 'd3';
import { format, subMonths } from 'date-fns';
import { HStack, Text, Icon } from '@chakra-ui/react';
import { formatDollars, formatMarketQuantity, formatSI } from '../../../../modules/format';
import { ChevronDown } from '../../../../shared/Icons';
import styles from './NCRs.module.css';
import ThisPeriod from './ThisPeriod';
import { Checkbox } from '@/components/ui/checkbox';
import { Tooltip } from '@/components/ui/tooltip';
import { RadioGroup, Radio } from '@/components/ui/radio';
import PropTypes from 'prop-types';

const ByCategory = props => {
  const { ncrsData, categoryColors, timePeriod, market, timeScale } = props;
  const data = ncrsData.categories;
  const [dataType, setDataType] = React.useState('count');
  const [withClaimValue, setWithClaimValue] = React.useState(false);
  const [withCompareAgainstPrevMonth, setWithCompareAgainstPrevMonth] = React.useState(false);

  const formatBasedOnDataType = React.useMemo(() => {
    if (dataType === 'count') {
      return val => val;
    }
    if (dataType === 'incidentRate') {
      return formatSI(3);
    }
    return formatDollars(0);
  }, [dataType]);

  const fieldName = withCompareAgainstPrevMonth
    ? `previous${dataType.charAt(0).toUpperCase()}${dataType.slice(1)}`
    : dataType;

  const maxCount = withCompareAgainstPrevMonth
    ? Math.max(...data.map(x => x[dataType]), ...data.map(x => x[fieldName]))
    : Math.max(...data.map(x => x[dataType]));

  const getTreeData = treeData => {
    if (!treeData) {
      return [];
    }
    const row = (d, isSecondary, color) => {
      const bar = (isPrevMonth, fieldName) => {
        const withClaimValueFieldName = isPrevMonth ? 'previousWithClaimValue' : 'withClaimValue';
        if (withClaimValue) {
          return (
            <div style={{ display: 'flex', marginTop: isPrevMonth ? '3px' : 0 }}>
              <Tooltip
                content={`${format(
                  isPrevMonth ? subMonths(timePeriod[0], 1) : timePeriod[0],
                  'MMMM yyyy'
                )} - ${formatBasedOnDataType(d[withClaimValueFieldName])} with claim value`}
              >
                <div
                  style={{
                    width:
                      d[withClaimValueFieldName] === 0 ? '0%' : `${(d[withClaimValueFieldName] * 100) / maxCount}%`,
                    height: isSecondary ? 7 : 10,
                    backgroundColor: color,
                  }}
                />
              </Tooltip>
              <Tooltip
                content={`${format(
                  isPrevMonth ? subMonths(timePeriod[0], 1) : timePeriod[0],
                  'MMMM yyyy'
                )} - ${formatBasedOnDataType(d[fieldName] - d[withClaimValueFieldName])} without claim value`}
              >
                <div
                  style={{
                    width:
                      d[fieldName] - d[withClaimValueFieldName] === 0
                        ? '0%'
                        : `${((d[fieldName] - d[withClaimValueFieldName]) * 100) / maxCount}%`,
                    height: isSecondary ? 7 : 10,
                    backgroundColor: color,
                    opacity: 0.5,
                  }}
                />
              </Tooltip>
            </div>
          );
        }
        let title = '';
        if (dataType === 'incidentRate') {
          title = `${format(isPrevMonth ? subMonths(timePeriod[0], 1) : timePeriod[0], 'MMMM yyyy')}
            ${formatBasedOnDataType(d[fieldName])} incidents per 10 tonnes 
            ${formatMarketQuantity(market)(isPrevMonth ? d.previousVolume : d.volume)} impacted volume`;
        } else {
          title = `${format(
            isPrevMonth ? subMonths(timePeriod[0], 1) : timePeriod[0],
            'MMMM yyyy'
          )} - ${formatBasedOnDataType(d[fieldName])}`;
        }
        return (
          <Tooltip overlayStyle={{ whiteSpace: 'pre-line' }} content={title}>
            <div
              style={{
                width: d[fieldName] === 0 ? '0%' : `${(d[fieldName] * 100) / maxCount}%`,
                height: isSecondary ? 7 : 10,
                backgroundColor: color,
                marginTop: isPrevMonth ? '3px' : 0,
                opacity: isPrevMonth ? 0.35 : 1,
              }}
            />
          </Tooltip>
        );
      };

      return (
        <div
          className={`${styles.QualityNcrs_tableRow} ${isSecondary ? styles.QualityNcrs_tableRowSecondary : ''}`}
          style={{ marginLeft: isSecondary ? -24 : 0 }}
        >
          <div
            style={{
              width: '40%',
              padding: '0 5px',
              paddingLeft: isSecondary ? 24 : 0,
            }}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {isSecondary ? d.subCategory : d.category}
            </div>
          </div>
          <div
            style={{
              width: '10%',
              padding: '0 5px',
              display: 'flex',
            }}
          >
            <div
              className={styles.QualityNcrs_tableRowValues}
              style={{
                width: withCompareAgainstPrevMonth ? '50%' : '100%',
                fontWeight: !isSecondary && 'bold',
              }}
            >
              {formatBasedOnDataType(d[dataType])}
            </div>
            {withCompareAgainstPrevMonth && (
              <div className={styles.QualityNcrs_tableRowValues} style={{ width: '50%', paddingLeft: '10px' }}>
                {formatBasedOnDataType(d[fieldName])}
              </div>
            )}
          </div>
          <div
            style={{
              width: '70%',
              padding: '0 5px 0 8px',
            }}
          >
            {bar(false, dataType)}
            {withCompareAgainstPrevMonth && bar(true, fieldName)}
          </div>
        </div>
      );
    };

    /**
     * If with Compare Against Prev Month checked, hide categories/subcategories with count=0 and previousCount=0
     * If without Compare Against Prev Month checked, hide categories/subcategories with count=0
     * */
    const filterOutZeroCount = data =>
      data.filter(
        d =>
          (withCompareAgainstPrevMonth && (d.count > 0 || d.previousCount > 0)) ||
          (!withCompareAgainstPrevMonth && d.count > 0)
      );

    return filterOutZeroCount(data.slice())
      .sort((a, b) => descending(a.count, b.count))
      .map((category, i) => {
        return {
          title: row(category, false, categoryColors[category.category]),
          key: `${i}-${category.name}`,
          children: filterOutZeroCount(category.subCategories).map((subCategory, j) => {
            return {
              title: row(subCategory, true, 'rgba(11, 20, 53, 0.6)'),
              key: `${i}-${j}-${subCategory.name}`,
            };
          }),
        };
      });
  };

  return (
    <div>
      <div className={styles.QualityNcrs_byCategoryTitle}>
        {/* <a> */}
        <Text className={styles.QualityNcrs_label}>by category</Text>
        {/* <Icon style={{ position: 'relative', width: 14 }} component={ChevronRight} /> */}
        {/* </a> */}
        <RadioGroup
          onValueChange={e => {
            setWithClaimValue(false);
            setDataType(e.value);
          }}
          defaultValue={dataType}
          size="sm"
          className={styles.QualityClaims_radio}
        >
          <HStack alignItems="center" justifyContent="end">
            <Radio key={1} value="count">
              Count
            </Radio>
            <Radio key={2} value="incidentRate">
              Incident Rate
            </Radio>
            <Radio key={3} value="claimAmount">
              Claim amount
            </Radio>
          </HStack>
        </RadioGroup>
      </div>
      {data.length > 0 ? (
        <>
          <Tree
            switcherIcon={
              <Icon style={{ opacity: 0.6 }}>
                <ChevronDown />
              </Icon>
            }
            selectable={false}
            treeData={getTreeData(data)}
            blockNode
          />
          <div className={styles.QualityNcrs_byCategoryOptions}>
            <Checkbox
              checked={withClaimValue}
              onChange={() => setWithClaimValue(!withClaimValue)}
              disabled={dataType === 'claimAmount' || dataType === 'incidentRate'}
            >
              Compare with/without claim value
            </Checkbox>
            <Checkbox
              data-test="compareAgainstPrevMonth-checkbox"
              checked={withCompareAgainstPrevMonth}
              onChange={() => setWithCompareAgainstPrevMonth(!withCompareAgainstPrevMonth)}
            >
              Compare against prev month
            </Checkbox>
          </div>
        </>
      ) : (
        <p>
          No incidents reported yet in <ThisPeriod timeScale={timeScale} timePeriod={timePeriod} />
        </p>
      )}
    </div>
  );
};

export default ByCategory;

ByCategory.propTypes = {
  ncrsData: PropTypes.shape({
    categories: PropTypes.arrayOf(
      PropTypes.shape({
        category: PropTypes.string.isRequired,
        count: PropTypes.number.isRequired,
        previousCount: PropTypes.number,
        subCategories: PropTypes.arrayOf(
          PropTypes.shape({
            subCategory: PropTypes.string.isRequired,
            count: PropTypes.number.isRequired,
            previousCount: PropTypes.number,
          })
        ),
      })
    ).isRequired,
  }).isRequired,
  categoryColors: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  timePeriod: PropTypes.arrayOf(PropTypes.instanceOf(Date)),
  market: PropTypes.string,
  timeScale: PropTypes.string,
};
