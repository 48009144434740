import React from 'react';
import { useSelector } from 'react-redux';
import { useChartData } from '../../../../../containers/analyticDashboard/qualityDetails/ncrs/QualityDetailsNCRsCharts';
import { calculateRoundedMax } from '../../../../../modules/data/rounded-max';
import { formatSI, formatWithCommas } from '../../../../../modules/format';
import { PACKER_MODE, TIME_RANGE_OPTIONS } from '../../../../../slices/quality-details-ncrs/qualityDetailsNcrsConfig';
import { selectQualityNCRsTimeRange } from '../../../../../slices/quality-details-ncrs/selectors/selectBasicDetailsNcrsData';
import BaseChart from '../../../treasury/BaseChart/BaseChart';
import {
  isLargePropTypes,
  columnsPropTypes,
  colorsPropTypes,
  selectedCategoriesPropTypes,
  selectedSubcategoriesPropTypes,
  packerModePropTypes,
} from '../../../../../slices/quality-details-ncrs/qualityDetailsNcrsPropTypes';
import styles from '../../QualityDetails.module.css';
import { Text } from '@chakra-ui/react';

export default function IncidentsChart(props) {
  const { isLarge, columns, colors, selectedCategories, selectedSubcategories, packerMode } = props;

  const timeRange = useSelector(selectQualityNCRsTimeRange);

  const incidentsData = useChartData(
    `incidentsCount${packerMode === PACKER_MODE.BOTTOM_10_TOTAL ? 'BottomTen' : ''}`,
    selectedCategories,
    selectedSubcategories
  );

  const total = React.useMemo(() => {
    let sum = 0;
    columns.forEach(x => {
      sum += incidentsData
        .filter(d => !!d)
        .map(d => ({ ...d, [x]: d[x] || 0 }))
        .reduce((a, b) => a + b[x], 0);
    });
    return sum;
  }, [incidentsData, columns]);

  if (incidentsData.length === 0) return null;
  columns.forEach(c => {
    if (colors[c] === undefined) {
      // TODO use sophisticated color picker
      colors[c] = '#4E5984';
    }
  });

  return (
    <div className={styles.QualityDetails_incidentsChart}>
      <div className={styles.QualityDetails_chartTitle}>
        <div style={{ color: '#0B1435' }}>NCR incident count</div>
        {timeRange === TIME_RANGE_OPTIONS.HISTORICAL && (
          <div className={styles.QualityDetails_chartTotal}>
            <Text>
              <span style={{ opacity: 0.6 }}>Total</span> <strong>{formatSI(1)(total || 0)}</strong>
            </Text>
          </div>
        )}
      </div>
      <BaseChart
        barData={incidentsData}
        columns={columns}
        colors={colors}
        isLarge={isLarge}
        splitIndex={-1}
        noCurrentLine
        noLegend
        ticksNumber={4}
        formatValues={formatSI(1)}
        pathColors={colors}
        minMaxGenerator={maxData => {
          return {
            maxVal: calculateRoundedMax(maxData),
            minVal: 0,
          };
        }}
        formatTooltip={val => `${formatWithCommas(val)} incidents`}
        padding={{ right: 2 }}
        isLineChart={timeRange === TIME_RANGE_OPTIONS.YOY}
        noLastPoint
        isYearOnYear={timeRange === TIME_RANGE_OPTIONS.YOY}
      />
    </div>
  );
}

IncidentsChart.propTypes = {
  isLarge: isLargePropTypes,
  columns: columnsPropTypes,
  colors: colorsPropTypes,
  selectedCategories: selectedCategoriesPropTypes,
  selectedSubcategories: selectedSubcategoriesPropTypes,
  packerMode: packerModePropTypes,
};
