import * as React from 'react';
import { Tree } from 'antd';
import { descending } from 'd3';
import { formatDollars, formatMarketQuantity, formatPercent, formatSI } from '../../../../modules/format';
import { ChevronDown } from '../../../../shared/Icons';
import styles from './NCRs.module.css';
import { Tooltip } from '@/components/ui/tooltip';
import { RadioGroup, Radio } from '@/components/ui/radio';
import { HStack, Text, Icon } from '@chakra-ui/react';

const NCRsDrivenByPackerPlants = props => {
  const { ncrsData, totalIncidents, categoryColors, market } = props;
  const data = ncrsData.packerPlants;
  const totalClaimDollars = ncrsData.dollars?.count;
  const [dataType, setDataType] = React.useState('count');

  const formatBasedOnDataType = React.useMemo(() => {
    if (dataType === 'count') {
      return val => val;
    } else if (dataType === 'incidentRate') {
      return formatSI(4);
    } else {
      return formatDollars(0);
    }
  }, [dataType]);

  const maxCount = Math.max(...data.map(x => x[dataType]));
  const packerPlants = data.slice();
  const dataSorted = packerPlants.sort((a, b) => b.count - a.count);
  const bottom5PackerPlants = dataSorted.slice(0, 5);
  const bottom5PackerPlantsIncidentsTotal = bottom5PackerPlants.reduce((a, b) => a + b.count, 0);
  const bottom5PackerPlantsDollarsTotal = bottom5PackerPlants.reduce((a, b) => a + b.claimAmount, 0);

  const bottom5PackerPlantsMinIncidentRate = Math.min(...bottom5PackerPlants.map(x => x.incidentRate));
  const bottom5PackerPlantsMaxIncidentRate = Math.max(...bottom5PackerPlants.map(x => x.incidentRate));

  const getTreeData = data => {
    if (!data) {
      return [];
    }
    const row = (d, isSecondary, color) => {
      return (
        <div
          className={`${styles.QualityNcrs_tableRow} ${isSecondary ? styles.QualityNcrs_tableRowSecondary : ''}`}
          style={{ marginLeft: isSecondary ? -24 : 0 }}
        >
          <div
            style={{
              width: '35%',
              padding: '0 5px',
              paddingLeft: isSecondary ? 24 : 0,
            }}
          >
            <div
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
              }}
            >
              {isSecondary ? d.category : d.packerPlantName}
            </div>
          </div>
          <div
            className={styles.QualityNcrs_tableRowValues}
            style={{
              width: '15%',
              padding: '0 5px',
              fontWeight: !isSecondary && 'bold',
            }}
          >
            {formatBasedOnDataType(d[dataType])}
          </div>
          <div
            style={{
              width: '50%',
              padding: '0 5px',
            }}
          >
            {isSecondary ? (
              <div
                style={{
                  width: `calc(${d[dataType] === 0 ? 0 : (d[dataType] * 100) / maxCount}% - 2px)`,
                  height: 7,
                  backgroundColor: color,
                }}
              />
            ) : (
              <div
                style={{
                  display: 'flex',
                  width: `${d[dataType] === 0 ? 0 : (d[dataType] * 100) / maxCount}%`,
                }}
              >
                {d.byCategory.map(c => {
                  let title = '';
                  if (dataType === 'incidentRate') {
                    title = `${c.category}
                    ${formatBasedOnDataType(c[dataType])} incidents per 10 tonnes
                    
                    ${formatMarketQuantity(market)(c.volume)} impacted volume`;
                  } else {
                    title = `${c.category} - ${formatBasedOnDataType(c[dataType])}`;
                  }
                  return (
                    <Tooltip key={`bar-${c.category}`} overlayStyle={{ whiteSpace: 'pre-line' }} content={title}>
                      <div
                        style={{
                          width: `${(c[dataType] * 100) / d[dataType]}%`,
                          height: 10,
                          backgroundColor: categoryColors[c.category],
                          marginRight: 2,
                        }}
                      />
                    </Tooltip>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      );
    };

    return bottom5PackerPlants
      .slice()
      .sort((a, b) => descending(a.count, b.count))
      .map((packerPlant, i) => {
        return {
          title: row(packerPlant, false, 'grey'),
          key: i + '-' + packerPlant.packerPlantId,
          children: packerPlant.byCategory.map((category, j) => {
            return {
              title: row(category, true, categoryColors[category.category]),
              key: i + '-' + j + '-' + category.category,
            };
          }),
        };
      });
  };

  let title = <div />;

  switch (dataType) {
    case 'count':
      let value = formatPercent(0)(bottom5PackerPlantsIncidentsTotal / totalIncidents);
      if (value === '-') {
        value = '0%';
      }
      title = (
        <>
          <span>{value}</span> of total NCRs
          <br />
          were driven by the following 5 establishments
        </>
      );
      break;
    case 'incidentRate':
      title = (
        <>
          <span>
            {formatSI(4)(bottom5PackerPlantsMinIncidentRate)} - {formatSI(4)(bottom5PackerPlantsMaxIncidentRate)}
          </span>{' '}
          incidents per 10 tonnes
          <br />
          were impacted among the following 5 establishments
        </>
      );
      break;
    case 'claimAmount':
      title = (
        <>
          <span>{formatPercent(0)(bottom5PackerPlantsDollarsTotal / totalClaimDollars)}</span> of total claim dollars
          <br />
          were driven by the following 5 establishments
        </>
      );
      break;
    default:
      break;
  }

  return (
    <div style={{ marginTop: 20 }}>
      <Text className={styles.QualityNcrs_incidentsPercent}>{title}</Text>
      <div className={styles.QualityNcrs_bottom5PackerPlantsTitle}>
        {bottom5PackerPlantsIncidentsTotal > 0 && (
          <>
            <Text className={styles.QualityNcrs_label}>bottom 5 establishments</Text>
            <RadioGroup
              onValueChange={e => {
                setDataType(e.value);
              }}
              defaultValue={dataType}
              size="sm"
              className={styles.QualityNcrs_radio}
            >
              <HStack alignItems="center" justifyContent="end">
                <Radio key={1} value="count">
                  Count
                </Radio>
                <Radio key={2} value="incidentRate">
                  Incident Rate
                </Radio>
                <Radio key={3} value="claimAmount">
                  Claim amount
                </Radio>
              </HStack>
            </RadioGroup>
          </>
        )}
      </div>
      <Tree
        switcherIcon={
          <Icon style={{ opacity: 0.6 }}>
            <ChevronDown />
          </Icon>
        }
        selectable={false}
        treeData={getTreeData(data)}
        blockNode
      />
      {/* <a className={styles.QualityNcrs_viewAll}>
        View all establishments
        <Icon style={{ position: 'relative', width: 14, top: 1 }}><ChevronRight /></Icon>
      </a> */}
    </div>
  );
};

export default NCRsDrivenByPackerPlants;
