import React from 'react';
import Select from 'react-select';
import { Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export default function FilterGrinder(props) {
  const { grinder, grinders, onActiveGrinderChange, ...rest } = props;

  return (
    <div>
      <Text
        style={{
          textTransform: 'uppercase',
          marginBottom: '5px',
          color: '#0B1435',
          opacity: 0.6,
        }}
      >
        Filter by grinder
      </Text>

      <Select
        showSearch
        value={{ label: grinder || '', value: grinder || '' }}
        styles={{
          container: styles => ({
            ...styles,
            padding: '0px',
            textAlign: 'left',
          }),
          clearIndicator: styles => ({
            ...styles,
            padding: '0px',
            ...rest.clearIndicatorStyles,
          }),
          dropdownIndicator: styles => ({
            ...styles,
            padding: '0px',
            ...rest.dropdownIndicatorStyles,
          }),
        }}
        onChange={(e) => onActiveGrinderChange(e.value)}
        options={grinders.map(opt => ({ label: opt, value: opt }))}
      />
    </div>
  );
}

FilterGrinder.propTypes = {
  grinder: PropTypes.string,
  grinders: PropTypes.arrayOf(PropTypes.string),
  onActiveGrinderChange: PropTypes.func,
};
