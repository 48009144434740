import React from 'react';
import { format } from 'date-fns';
import { formatDollars } from '../../../../modules/format';
import { TREASURY_DETAILS_COST } from '../../../../slices/treasury-details/treasuryDetailsReducers';
import BaseChart from '../BaseChart/BaseChart';
import DataTable from '../DataTable/DataTable';
import AnnotationLayer from '../../../../shared/AnnotationLayer/AnnotationLayer';
import { Checkbox } from '@/components/ui/checkbox';

const colors = {
  Formula: '#1CA6B2',
  Spot: '#F01D82',
  Contract: '#805CFF',
  NOF: '#F2C94C',
  'Financial derivative': '#00B5F2',
  'Frozen product usage': '#FC5D36',
  Hedged: '#006df2',
  Exposed: '#FF9A3D',
  Fat: '#545B72',
  Lean: '#AB2424',
  Total: '#029916',
};

const columns = ['Formula', 'Spot', 'Contract', 'NOF', 'Financial derivative', 'Frozen product usage'];
const singleColumn = ['Savings'];

const breakdownArrayToObject = (breakdown, chartType) =>
  breakdown.reduce(
    (acc, n) => ({
      ...acc,
      [n.orderType]: chartType === TREASURY_DETAILS_COST.TOTAL ? n.costTotal : n.costSavings,
    }),
    {}
  );

export default function CostChart(props) {
  const {
    costSavingsData,
    splitIndex,
    addNewAnnotation,
    editAnnotation,
    moduleAnnotations,
    annotationIntervalFormat,
    isLarge,
    chartType,
    strategies,
    hasShowBreakdownOption,
    withDataTable,
    withMultiOptionTable,
    forceColor,
  } = props;

  let barData = costSavingsData.map(({ interval, rawInterval, breakdown, annotation }) => {
    return {
      interval,
      rawInterval,
      annotation,
      ...breakdownArrayToObject(breakdown, chartType),
    };
  });

  barData = hasShowBreakdownOption ? barData.slice(0, splitIndex) : barData;

  const [breakdown, setBreakdown] = React.useState(true);

  return (
    <>
      <BaseChart
        barData={
          breakdown
            ? barData
            : barData.map(x => {
                return {
                  interval: x.interval,
                  Savings: Object.values(x)
                    .filter(v => typeof v === 'number')
                    .reduce((a, b) => a + b),
                };
              })
        }
        columns={breakdown ? strategies || columns : singleColumn}
        splitIndex={splitIndex === -1 || splitIndex === barData.length - 1 ? -1 : splitIndex}
        colors={forceColor || colors}
        isLarge={isLarge}
        formatValues={v => `${formatDollars(2)(v)}`}
        minMaxGenerator={(maxData, minData) => {
          return {
            maxVal: maxData,
            minVal: chartType === TREASURY_DETAILS_COST.TOTAL ? 0 : minData,
          };
        }}
        ticksNumber={7}
        isBicolored={!breakdown}
        tableContent={chartProps => {
          return withDataTable && <DataTable {...chartProps} multiOption={withMultiOptionTable} />;
        }}
        AnnotationLayer={p => {
          if (!p.scales) return <div />;
          return (
            <AnnotationLayer
              data={p.barData}
              xScale={p.scales.x}
              splitIndex={p.splitIndex}
              addNewAnnotation={addNewAnnotation}
              editAnnotation={editAnnotation}
              moduleAnnotations={moduleAnnotations}
              getXValue={d => format(d.timestamp, annotationIntervalFormat)}
            />
          );
        }}
      />
      {hasShowBreakdownOption && (
        <Checkbox onChange={() => setBreakdown(!breakdown)} checked={breakdown}>
          Show breakdown by strategy
        </Checkbox>
      )}
    </>
  );
}
