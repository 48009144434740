import React from 'react';
import styles from '../Filter.module.css';
import { Separator, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { NativeSelectField, NativeSelectRoot } from '@/components/ui/native-select';

export default function FilterSelectGeneric(props) {
  const {
    value,
    onChange,
    options,
    optionLabels,
    label,
    includeNullOption,
    placeholder,
    allowClear,
    isDisabled,
    withColors,
    dataColors,
  } = props;

  function handleChange(v) {
    onChange(v);
  }

  return (
    <div data-with-colors={withColors} data-color={dataColors && dataColors[value]}>
      {label && <Text className={styles.filterLabel}>{label}</Text>}

      <NativeSelectRoot>
        <NativeSelectField
          zIndex="10000"
          placeholder="Select"
          value={value}
          onChange={handleChange}
          placeholder={placeholder}
          allowClear={allowClear}
          disabled={isDisabled}
        >
          {includeNullOption && <option value={null}>--</option>}
          {options.map(o => {
            if (o === 'Divider') {
              return (
                <option key={o} disabled style={{ overflow: 'none', minHeight: 5 }}>
                  <Separator style={{ margin: 0 }} />
                </option>
              );
            }

            return (
              <option value={o} key={o}>
                {optionLabels ? optionLabels[o] : o}
              </option>
            );
          })}
        </NativeSelectField>
      </NativeSelectRoot>

      {/* <Select
        value={value}
        className={styles.selectBox}
        onChange={handleChange}
        placeholder={placeholder}
        allowClear={allowClear}
        disabled={isDisabled}
      >
        {includeNullOption && <Select.Option value={null}>--</Select.Option>}
        {options.map(o => {
          if (o === 'Divider') {
            return (
              <Select.Option key={o} disabled style={{ overflow: 'none', minHeight: 5 }}>
                <Separator style={{ margin: 0 }} />
              </Select.Option>
            );
          }
          return (
            <Select.Option key={o} value={o}>
              {optionLabels ? optionLabels[o] : o}
            </Select.Option>
          );
        })}
      </Select> */}
    </div>
  );
}

FilterSelectGeneric.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
  optionLabels: PropTypes.shape({}),
  label: PropTypes.string,
  includeNullOption: PropTypes.bool,
  placeholder: PropTypes.string,
  allowClear: PropTypes.bool,
  isDisabled: PropTypes.bool,
  withColors: PropTypes.bool,
  dataColors: PropTypes.shape({}),
};
