import React from 'react';
import { format } from 'date-fns';
import { formatDollars } from '../../../../modules/format';
import { TREASURY_DETAILS_PRICE } from '../../../../slices/treasury-details/treasuryDetailsReducers';
import BaseChart from '../BaseChart/BaseChart';
import AnnotationLayer from '../../../../shared/AnnotationLayer/AnnotationLayer';
import styles from './PriceChart.module.css';
import { Checkbox } from '@/components/ui/checkbox';

const columnsDefault = ['fmg_price', 'usda_price'];
const range = { high: 'fmg_high', low: 'fmg_low' };
const legendLabels = {
  usda_price: 'USDA Market Px (76CL WAVG)',
  fmg_price: 'FMG Forecast Px (76CL WAVG)',
  usda_fat: 'Fat beef',
  usda_lean: 'Lean beef',
  usda_50CL: '50CL',
  usda_65CL: '65CL',
  usda_78CL: '78CL',
  usda_90CL: '90CL',
  mecardo_price: 'Mecardo',
  mla_price: 'MLA',
};
const tooltipLabels = {
  usda_price: 'USDA Market Px',
  fmg_price: 'FMG Forecast Px',
  usda_fat: 'Fat beef',
  usda_lean: 'Lean beef',
  usda_50CL: '50CL',
  usda_65CL: '65CL',
  usda_78CL: '78CL',
  usda_90CL: '90CL',
  mecardo_price: 'Mecardo',
  mla_price: 'MLA',
};
const colors = {
  usda_price: '#FC5D36',
  fmg_price: '#0B1435',
  usda_fat: '#545B72',
  usda_lean: '#AB2424',
  usda_50CL: '#545B72B3',
  usda_65CL: '#545B7280',
  usda_78CL: '#AB2424B3',
  usda_90CL: '#AB242480',
  mecardo_price: '#fcc736',
  mla_price: '#fcef36',
};
const extraTooltipRows = data => [
  {
    value: data.fmg_price - data.usda_price,
    label: 'Mark-to-Market',
    condition: !!data.usda_price,
  },
];
const colorsPerStrategy = {
  Formula: '#1CA6B2',
  Spot: '#F01D82',
  Contract: '#805CFF',
  NOF: '#F2C94C',
  'Financial derivative': '#00B5F2',
  'Frozen product usage': '#FC5D36',
  Hedged: '#006df2',
  Exposed: '#FF9A3D',
  Fat: '#545B72',
  Lean: '#AB2424',
  Total: '#029916',
};

const breakdownArrayToObject = (breakdown, costPriceType) =>
  breakdown.reduce(
    (acc, n) => ({
      ...acc,
      [n.orderType]: costPriceType === 'median' ? n.medianCostPrice : n.averageCostPrice,
      [`${n.orderType}-usda`]: n.usdaCostPrice,
    }),
    {}
  );

export default function PriceChart(props) {
  const {
    data,
    splitIndex,
    addNewAnnotation,
    editAnnotation,
    moduleAnnotations,
    isLarge,
    chartType,
    strategies,
    costPriceType,
    forceColor,
    annotationIntervalFormat,
  } = props;
  const [showRange, setShowRange] = React.useState(true);

  let barData = data;
  if (chartType !== TREASURY_DETAILS_PRICE.MARKET_PRICE_AND_FORECAST) {
    barData = data.map(({ interval, rawInterval, annotation, breakdown }) => {
      return {
        interval,
        rawInterval,
        annotation,
        ...breakdownArrayToObject(breakdown, costPriceType),
      };
    });
  }

  const isMarketPriceChart = chartType === TREASURY_DETAILS_PRICE.MARKET_PRICE_AND_FORECAST;
  const isSavingsChart = chartType === TREASURY_DETAILS_PRICE.PRICE_SAVINGS;
  const cols = strategies || columnsDefault;

  const isRangeRelevant = isMarketPriceChart && cols.includes('fmg_price');

  const customContent = (barData, scales, splitIndex, setTooltip, showRange) => {
    return (
      <Range
        data={barData}
        scales={scales}
        splitIndex={splitIndex}
        showRange={isRangeRelevant && showRange}
        setTooltip={setTooltip}
        isMarketPriceChart={isMarketPriceChart}
        isSavingsChart={isSavingsChart}
        columns={cols}
        forceColor={forceColor}
      />
    );
  };

  return (
    <>
      <BaseChart
        barData={barData}
        columns={cols}
        legendLabels={isMarketPriceChart ? legendLabels : undefined}
        colors={!isMarketPriceChart ? forceColor || colorsPerStrategy : colors}
        isLarge={isLarge}
        splitIndex={splitIndex}
        formatValues={v => `${formatDollars(2)(v)}`}
        ticksNumber={7}
        customContent={({ barData, scales, splitIndex, setTooltip }) =>
          customContent(barData, scales, splitIndex, setTooltip, showRange)
        }
        AnnotationLayer={p => {
          if (!p.scales) return <div />;
          return (
            <AnnotationLayer
              data={p.barData}
              xScale={p.scales.x}
              splitIndex={p.splitIndex}
              addNewAnnotation={addNewAnnotation}
              editAnnotation={editAnnotation}
              moduleAnnotations={moduleAnnotations}
              getXValue={d => format(d.timestamp, annotationIntervalFormat)}
            />
          );
        }}
      />
      {isMarketPriceChart && isRangeRelevant && (
        <Checkbox onChange={() => setShowRange(!showRange)} checked={showRange} style={{ marginBottom: '20px' }}>
          Show forecast range
        </Checkbox>
      )}
    </>
  );
}

function Range(props) {
  const { data, scales, splitIndex, showRange, setTooltip, isMarketPriceChart, isSavingsChart, columns, forceColor } =
    props;

  const bandWidth = scales.x.bandwidth();
  const tooltipOffset = 20;

  const TOOLTIP_WIDTH = 200;
  const TOOLTIP_HEIGHT = 85;

  const getTooltipPosition = (data, index) => {
    const values = columns.map(c => data[c]).filter(x => x !== null && x !== undefined);
    const valuesUsda = isSavingsChart
      ? columns.map(c => data[`${c}-usda`]).filter(x => x !== null && x !== undefined)
      : [];
    const tmp = values.concat(valuesUsda);
    const yMax = Math.max(...tmp);
    let x = scales.x(data.interval) + bandWidth / 2 - TOOLTIP_WIDTH / 2;
    let y = scales.y(yMax) - TOOLTIP_HEIGHT - tooltipOffset;

    if (splitIndex !== -1 && index >= splitIndex) {
      x += 20;
    }

    if (y < -84) {
      y = -84;
    }

    return { x, y };
  };

  const tooltip = (data, index) => (
    <div
      className={styles.tooltip}
      style={{
        width: TOOLTIP_WIDTH,
        left: getTooltipPosition(data, index).x,
        top: getTooltipPosition(data, index).y,
      }}
    >
      <div className={styles.tooltipTitle}>{data.interval}</div>
      {isSavingsChart &&
        columns.length === 1 &&
        columns.map(c =>
          data[c] ? (
            <>
              <div className={styles.tooltipRow} key={`label-${c}`}>
                <div className={styles.tooltipNumber}>{`${formatDollars(2)(data[`${c}-usda`])}`}</div>
                <div className={styles.tooltipLabel}>USDA WAVG price</div>
              </div>
              <div className={styles.tooltipRow} key={`label-${c}`}>
                <div className={styles.tooltipNumber}>{`${formatDollars(2)(data[c])}`}</div>
                <div className={styles.tooltipLabel}>{c}</div>
              </div>
            </>
          ) : null
        )}
      {columns.map(c =>
        data[c] ? (
          <div className={styles.tooltipRow} key={`label-${c}`}>
            <div className={styles.tooltipNumber}>
              {isSavingsChart ? `${formatDollars(2)(data[`${c}-usda`] - data[c])}` : `${formatDollars(2)(data[c])}`}
            </div>
            <div className={styles.tooltipLabel}>
              {isMarketPriceChart ? tooltipLabels[c] : isSavingsChart ? `${c} - savings` : c}
            </div>
          </div>
        ) : null
      )}
      {extraTooltipRows(data).map(
        row =>
          row.condition && (
            <div className={styles.tooltipRow} key={`label-${row.label}`}>
              <div className={styles.tooltipNumber}>{formatDollars(2)(row.value)}</div>
              <div className={styles.tooltipLabel}>{row.label}</div>
            </div>
          )
      )}
    </div>
  );

  return (
    <>
      {data.map((d, i) => {
        const values = columns.map(c => d[c]).filter(x => x !== null && x !== undefined && !isNaN(x));
        const valuesUsda = isSavingsChart
          ? columns.map(c => d[`${c}-usda`]).filter(x => x !== null && x !== undefined && !isNaN(x))
          : [];
        const tmp = values.concat(valuesUsda);
        const yMax = tmp.length ? Math.max(...tmp) : 0;
        const yMin = tmp.length ? Math.min(...tmp) : 0;
        return (
          <g
            key={`${Math.random()}-bars`}
            style={i >= splitIndex && splitIndex !== -1 ? { transform: 'translateX(20px)' } : undefined}
          >
            <g>
              {showRange && d[range.high] && d[range.low] && (
                <rect
                  x={scales.x(d.interval)}
                  y={scales.y(d[range.high])}
                  width={bandWidth}
                  height={scales.y(d[range.low]) - scales.y(d[range.high])}
                  fill="#0B1435"
                  opacity={0.1}
                />
              )}
              {columns.map(c => {
                if (!d[c]) {
                  return null;
                }
                const color = (!isMarketPriceChart ? forceColor || colorsPerStrategy : colors)[c];
                return (
                  <g key={`line-${c}`}>
                    <line
                      x1={scales.x(d.interval)}
                      y1={scales.y(d[c])}
                      x2={scales.x(d.interval) + bandWidth}
                      y2={scales.y(d[c])}
                      stroke={color}
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeDasharray={i >= splitIndex && splitIndex !== -1 ? '2 4' : undefined}
                    />
                    {d[`${c}-usda`] && isSavingsChart && (
                      <rect
                        x={scales.x(d.interval)}
                        y={scales.y(Math.max(d[`${c}-usda`], d[c]))}
                        width={bandWidth}
                        height={scales.y(Math.min(d[`${c}-usda`], d[c])) - scales.y(Math.max(d[`${c}-usda`], d[c]))}
                        fill={color}
                        opacity={0.3}
                      />
                    )}
                    {data[i + 1] && data[i + 1][c] && (
                      <line
                        x1={scales.x(d.interval) + bandWidth}
                        y1={scales.y(d[c])}
                        x2={scales.x(d.interval) + bandWidth}
                        y2={scales.y(data[i + 1][c])}
                        stroke={color}
                        strokeLinecap="round"
                        strokeWidth={2}
                        strokeDasharray={i >= splitIndex && splitIndex !== -1 ? '2 4' : undefined}
                      />
                    )}
                  </g>
                );
              })}
              {!(yMax === 0 && yMin === 0) && (
                <rect
                  x={scales.x(d.interval)}
                  y={scales.y(yMax) - tooltipOffset}
                  width={bandWidth}
                  height={scales.y(yMin) - scales.y(yMax) + tooltipOffset * 2}
                  fill="#fff"
                  opacity={0}
                  onMouseEnter={() => setTooltip(tooltip(d, i))}
                  onMouseLeave={() => setTooltip(undefined)}
                />
              )}
            </g>
          </g>
        );
      })}
    </>
  );
}
