import React from 'react';
import Select from 'react-select';
import { Separator } from '@chakra-ui/react';
import PropTypes from 'prop-types';

export default function FilterPackerPlants(props) {
  const { value, options, onChange, ...rest } = props;

  const handleChange = val => {
    const selectedPackerPlant = options.find(x => x.id === val?.value);
    if (selectedPackerPlant) {
      onChange(selectedPackerPlant);
    }
  };

  const createOption = (name, est, id, isPacker) => {
    if (isPacker) {
      return { label: `--- ${name} (${id}) ---`, value: id };
    }
    if (['All', 'Australia', 'New Zealand'].includes(id)) {
      return { label: name, value: id };
    }
    if (id === 'Divider') {
      return { label: <Separator />, value: id, isDisabled: true };
    }
    return { label: `${name} (${est})`, value: id };
  };

  return (
    <Select
      showSearch
      value={{ label: value?.name || '', value: value?.id || '' }}
      styles={{
        container: styles => ({
          ...styles,
          padding: '0px',
          textAlign: 'left',
        }),
        control: styles => ({
          ...styles,
          maxHeight: '225px',
          width: '234px',
          overflowY: 'scroll',
          ...rest.controlStyles,
        }),
        clearIndicator: styles => ({
          ...styles,
          padding: '0px',
          ...rest.clearIndicatorStyles,
        }),
        dropdownIndicator: styles => ({
          ...styles,
          padding: '0px',
          ...rest.dropdownIndicatorStyles,
        }),
      }}
      onChange={handleChange}
      options={options.map(({ est, id, name, isPacker }) => {
        return createOption(name, est, id, isPacker);
      })}
    />
  );
}

FilterPackerPlants.propTypes = {
  value: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
  }),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      est: PropTypes.string,
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      isPacker: PropTypes.bool,
    })
  ),
  onChange: PropTypes.func,
};
